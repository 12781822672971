export default function (state, { type, payload }) {
	switch (type) {
		case 'SET_THEME':
			localStorage.setItem('theme', payload)
			if (payload === 'dark') {
				document.body.classList.add('theme-dark')
			} else {
				document.body.classList.remove('theme-dark')
			}

			return { ...state, theme: payload }
			break

		case 'SET_HAS_LOADER':
			return { ...state, hasLoader: payload }
			break

		case 'SET_USER':
			return { ...state, user: payload }
			break

		default:
			return state
	}
}
