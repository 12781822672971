import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import WorkshopForm from '../../components/forms/WorkshopForm'

export default function WorkshopCreate() {
	const navigate = useNavigate()

	const initialValues = {
		title: '',
		description: '',
		instructor: '',
		image: '',
		titleEn: '',
		descriptionEn: '',
		price: '',
		isCurrent: false,
		order: 0
	}

	const [saveLoading, setSaveLoading] = useState(false)

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.post('/ateliers', payload)
			.then(({ data }) => {
				toast.success('Atölye eklendi!')
				navigate(`/workshops/${data._id}/sessions/create`)
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Yeni Atölye">
			<WorkshopForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />
		</PageLayout>
	)
}
