import Yup from './config'

export const FilterGroupSchema = Yup.object().shape({
	name: Yup.string().required(),
	nameEn: Yup.string()
})

export const FilterItemSchema = Yup.object().shape({
	name: Yup.string().required(),
	nameEn: Yup.string(),
	groupId: Yup.object().required()
})
