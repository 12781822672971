import Button from '../shared/Button'

function SaveButton({ ...props }) {
	return (
		<Button type={!props.type ? 'submit' : 'button'} variant="success" icon="bi bi-check-circle" {...props}>
			Kaydet
		</Button>
	)
}

export default SaveButton
