import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import ExhibitionForm from '../../components/forms/ExhibitionForm'

export default function ExhibitionCreate() {
	const navigate = useNavigate()

	const initialValues = {
		name: '',
		description: '',
		works: [],
		isCurrent: false,
		order: 0,
		image: '',
		startDate: '',
		endDate: '',
		nameEn: '',
		descriptionEn: ''
	}

	const [saveLoading, setSaveLoading] = useState(false)

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.post('/exhibitions', payload)
			.then(() => {
				toast.success('Sergi eklendi!')
				navigate('/exhibitions')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Yeni Sergi">
			<ExhibitionForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />
		</PageLayout>
	)
}
