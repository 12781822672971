import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import ArtistForm from '../../components/forms/ArtistForm'

export function ArtistEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [requestLoader, setRequestLoader] = useState(true)
	const [initialValues, setInitialValues] = useState({
		name: '',
		bio: '',
		bioEn: '',
		image: '',
		type: 'artist'
	})
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/artists/${id}`)
			.then(({ data }) => {
				setInitialValues({
					name: data.name,
					bio: data.bio,
					bioEn: data.translations.en.bio,
					image: data.image,
					type: data.type
				})
				setRequestLoader(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const handleSubmit = (values) => {
		setSaveLoading(true)

		const payload = {
			...values,
			translations: {
				en: {
					bio: values.bioEn
				}
			}
		}
		delete payload.bioEn

		http
			.patch(`/artists/${id}`, payload)
			.then(() => {
				toast.success('Sanatçı düzenlendi!')
				navigate('/artists')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Sanatçıyı Düzenle">
			{requestLoader && <CardLoader />}
			{!requestLoader && <ArtistForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />}
		</PageLayout>
	)
}
