import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function DropdownButton({ children, variant, size, rounded, options, onOptionClick }) {
	const buttonEl = useRef()
	const [isOpen, setIsOpen] = useState(false)
	const styles = classNames('btn', `btn-${variant}`, `btn-${size}`, { show: isOpen, 'rounded-pill': rounded })
	const [dropdwonStyle, setDropdownStyle] = useState({ position: 'fixed' })

	const handleToggle = ({ target }) => {
		const rect = target.getBoundingClientRect()
		setDropdownStyle({ ...dropdwonStyle, top: rect.top + rect.height + 5, left: rect.left })
		setIsOpen(!isOpen)
	}

	const clickOutsideFromDropdown = ({ target }) => {
		if (!buttonEl.current?.contains(target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('click', clickOutsideFromDropdown)
		document.addEventListener('scroll', clickOutsideFromDropdown)

		return () => {
			document.removeEventListener('click', clickOutsideFromDropdown)
			document.removeEventListener('scroll', clickOutsideFromDropdown)
		}
	}, [])

	return (
		<div className="dropdown">
			<button ref={buttonEl} type="button" className={styles} onClick={handleToggle}>
				{children}
			</button>
			<div className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={dropdwonStyle}>
				{options.map((option, index) => (
					<button key={index} className="dropdown-item text-sm d-flex gap-1" onClick={() => onOptionClick(option)}>
						{option.icon && <i className={option.icon} style={{ marginTop: -1 }}></i>}
						{option.text}
					</button>
				))}
			</div>
		</div>
	)
}

DropdownButton.propTypes = {
	varinat: PropTypes.string,
	size: PropTypes.string,
	rounded: PropTypes.bool
}

DropdownButton.defaultProps = {
	variant: 'primary',
	size: 'sm',
	rounded: false
}

export default DropdownButton
