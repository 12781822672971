import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { moment } from '../../utils/helpers/index'
import http from '../../utils/config/service'
import { paymentStatusValues } from '../../utils/config/constants'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import Card from '../../components/shared/Card'
import FormGroup from '../../components/shared/FormGroup'
import SaveButton from '../../components/buttons/SaveButton'
import CancelButton from '../../components/buttons/CancelButton'

export default function ParticipantDetail() {
	const { workshopId, id } = useParams()

	const [cardLoading, setCardLoading] = useState(true)
	const [paymentStatus, setPaymentStatus] = useState('waiting')
	const [workshop, setWorkshop] = useState(null)
	const [participant, setParticipant] = useState(null)
	const [saveLoading, setSaveLoading] = useState(false)

	const handleSubmit = (e) => {
		e.preventDefault()
		setSaveLoading(true)

		http
			.patch(`/ateliers/participant/${id}`, { paymentStatus })
			.then(() => {
				toast.success('Ödeme durumu güncellendi!')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	const fetchWorkshop = () => {
		http
			.get(`/ateliers/${workshopId}`)
			.then(({ data }) => {
				setWorkshop(data)
			})
			.catch((err) => console.log(err))
	}

	const fetchParticipant = () => {
		http
			.get(`/ateliers/participant/${id}`)
			.then(({ data }) => {
				setCardLoading(false)
				setParticipant(data)
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		fetchWorkshop()
		fetchParticipant()
	}, [])

	return (
		<PageLayout title={workshop ? workshop.title : '...'} subtitle="Katılımcı Detayı">
			{cardLoading && (
				<Card>
					<CardLoader />
				</Card>
			)}
			{!cardLoading && (
				<div className="row">
					<div className="col-md-8">
						<Card>
							<table className="table">
								<tbody>
									<tr>
										<td style={{ width: '20%' }}>
											<strong>Ad Soyad</strong>
										</td>
										<td>
											{participant.name} {participant.surname}
										</td>
									</tr>
									<tr>
										<td style={{ width: '20%' }}>
											<strong>E-Posta</strong>
										</td>
										<td>
											<a href={`mailto:${participant.email}`}>{participant.email}</a>
										</td>
									</tr>
									<tr>
										<td style={{ width: '20%' }}>
											<strong>Telefon</strong>
										</td>
										<td>
											<a href={`tel:${participant.phone}`}>{participant.phone}</a>
										</td>
									</tr>
									<tr>
										<td style={{ width: '20%' }}>
											<strong>Seans</strong>
										</td>
										<td>
											{moment(participant.session.date).format('DD.MM.Y')} {participant.session.time}
										</td>
									</tr>
									<tr>
										<td style={{ width: '20%' }}>
											<strong>Kayıt Tarihi</strong>
										</td>
										<td>{moment(participant.createdAt).format('DD.MM.Y HH:mm')}</td>
									</tr>
									<tr>
										<td style={{ width: '20%' }}>
											<strong>Mesaj</strong>
										</td>
										<td></td>
									</tr>
									<tr>
										<td colSpan="2">{participant.message}</td>
									</tr>
								</tbody>
							</table>
						</Card>
					</div>
					<div className="col-md-4">
						<Card>
							<form onSubmit={handleSubmit}>
								<FormGroup label="Ödeme Durumu" labelFor="paymentStatus">
									<select
										className="form-select"
										id="paymentStatus"
										value={paymentStatus}
										onChange={({ target }) => setPaymentStatus(target.value)}>
										{paymentStatusValues.map((status, index) => (
											<option key={index} value={status.value}>
												{status.label}
											</option>
										))}
									</select>
								</FormGroup>
								<hr />
								<div className="text-end">
									<CancelButton link={`/workshops/${workshopId}/participants`} />
									<SaveButton loading={saveLoading} />
								</div>
							</form>
						</Card>
					</div>
				</div>
			)}
		</PageLayout>
	)
}
