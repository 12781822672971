import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import WorkForm from '../../components/forms/WorkForm'

export function WorkCreate() {
	const navigate = useNavigate()
	const initialValues = {
		artistId: '',
		stockCode: '',
		stock: '',
		price: '',
		discountedPrice: '',
		shippingPrice: '',
		hasVat: true,
		vatRate: 20,
		name: '',
		description: '',
		gallery: [{ image: '' }],
		filters: [],
		status: true,
		nameEn: '',
		descriptionEn: ''
	}

	const [saveLoading, setSaveLoading] = useState(false)

	const handleSubmit = (values) => {
		setSaveLoading(true)

		const payload = {
			...values,
			artistId: values.artistId.value,
			discountedPrice: values.discountedPrice || 0,
			filters: values.filters.map((filter) => filter.value),
			gallery: values.gallery.map((item) => item.image),
			vatRate: values.vatRate || 0,
			translations: {
				en: {
					name: values.nameEn,
					description: values.descriptionEn
				}
			}
		}
		delete payload.nameEn
		delete payload.descriptionEn

		http
			.post('/works', payload)
			.then(() => {
				toast.success('Eser eklendi!')
				navigate('/works')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Yeni Eser">
			<WorkForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />
		</PageLayout>
	)
}
