import Yup from './config'

export const CreateUserSchema = Yup.object().shape({
	email: Yup.string().email().required(),
	password: Yup.string().required().min(8).max(16),
	rePassword: Yup.string()
		.required()
		.min(8)
		.max(16)
		.oneOf([Yup.ref('password'), null]),
	fullName: Yup.string().required(),
	isActive: Yup.boolean()
})

export const UpdateUserSchema = Yup.object().shape({
	email: Yup.string().email().required(),
	password: Yup.string().min(8).max(16),
	rePassword: Yup.string()
		.min(8)
		.max(16)
		.oneOf([Yup.ref('password'), null]),
	fullName: Yup.string().required(),
	isActive: Yup.boolean()
})
