function NewImageButton({ ...props }) {
	return (
		<button type="button" className="btn btn-light btn-block h-100" style={{ minHeight: 196 }} {...props}>
			<span className="d-block" style={{ fontSize: 24 }}>
				+
			</span>
			<span>Yeni Görsel</span>
		</button>
	)
}

export default NewImageButton
