import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/shared/Card'
import MediaComponent from '../../components/media'

function Media() {
	return (
		<PageLayout title="Ortam Kütüphanesi">
			<Card>
				<MediaComponent />
			</Card>
		</PageLayout>
	)
}

export default Media
