import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import routes from './routes'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
	const theme = useSelector((s) => s.theme)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch({ type: 'SET_THEME', payload: localStorage.getItem('theme') || 'light' })
	}, [])

	return (
		<>
			{useRoutes(routes)}
			<ToastContainer theme={theme} />
		</>
	)
}

export default App
