import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'
import { status, paymentStatus, paymentType, deliveryType } from '../../utils/config/constants'

import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/shared/Card'
import Table from '../../components/shared/Table'

const columns = [
	{
		name: 'Sipariş No',
		selector: (row) => row.conversationId,
		sortable: true
	},
	{
		name: 'Alıcı Tipi',
		selector: (row) => (row.buyerType === 'guest' ? 'Misafir' : 'Müşteri'),
		sortable: true
	},
	{
		name: 'Alıcı',
		selector: (row) => `${row.buyer.name} ${row.buyer.surname}`,
		sortable: true
	},
	{
		name: 'Ödeme Tipi',
		selector: (row) => paymentType[row.paymentType],
		sortable: true
	},
	{
		name: 'Teslimat Tipi',
		selector: (row) => deliveryType[row.deliveryType],
		sortable: true
	},
	{
		name: 'Durum',
		cell: (row) => <span className={`badge bg-light-${status[row.status].className}`}>{status[row.status].text}</span>,
		selector: (row) => status[row.status].text,
		sortable: true,
		button: true
	},
	{
		name: 'Ödeme',
		cell: (row) => (
			<span className={`badge bg-light-${paymentStatus[row.paymentStatus].className}`}>
				{paymentStatus[row.paymentStatus].text}
			</span>
		),
		selector: (row) => paymentStatus[row.paymentStatus].text,
		sortable: true,
		button: true
	}
]

export function Orders() {
	const navigate = useNavigate()
	const [orders, setOrders] = useState([])
	const [pending, setPending] = useState(true)

	const fetchOrders = () => {
		http
			.get('/orders')
			.then(({ data }) => setOrders(data))
			.catch((err) => console.log(err))
			.finally(() => setPending(false))
	}

	const firedAction = ({ text, param }) => {
		switch (text) {
			case 'Detaylar':
				navigate(`/orders/detail/${param}`)
				break

			default:
				break
		}
	}

	useEffect(() => {
		fetchOrders()
	}, [])

	return (
		<PageLayout title="Siparişler">
			<Card>
				<Table
					removable={false}
					editable={false}
					actions={[{ icon: 'bi bi-search', text: 'Detaylar' }]}
					columns={columns}
					data={orders}
					pending={pending}
					firedAction={firedAction}
				/>
			</Card>
		</PageLayout>
	)
}
