import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function Modal({ children, size, isShowing, title, footer, onClose }) {
	const [showing, setShowing] = useState(isShowing)

	const styles = classNames({
		'modal fade text-left modal-borderless': true,
		show: showing,
		[`modal-${size}`]: !!size
	})

	const closeModal = () => {
		setShowing(false)
		onClose()
	}

	const handleClickOutside = ({ target, currentTarget }) => {
		if (target === currentTarget) {
			closeModal()
		}
	}

	useEffect(() => {
		setShowing(isShowing)
	}, [isShowing])

	return (
		<>
			<div className={styles} style={{ display: showing ? 'block' : 'none' }} onClick={handleClickOutside}>
				<div className="modal-dialog modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
							<button type="button" className="btn rounded-pill" onClick={closeModal}>
								<i className="bi bi-x-lg"></i>
							</button>
						</div>
						<div className="modal-body">{children}</div>
						{footer && <div className="modal-footer">{footer}</div>}
					</div>
				</div>
			</div>
			{showing && <div className="modal-backdrop fade show"></div>}
		</>
	)
}

Modal.propTypes = {
	size: PropTypes.string,
	isShowing: PropTypes.bool,
	title: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired
}

Modal.defaultProps = {
	size: '',
	isShowing: false
}

export default Modal
