import PropTypes from 'prop-types'

import { Formik, Form } from 'formik'

import FormGroup from '../shared/FormGroup'
import Textbox from '../shared/Textbox'
import Checkbox from '../shared/Checkbox'
import CancelButton from '../buttons/CancelButton'
import SaveButton from '../buttons/SaveButton'

function CustomerForm({ initialValues, validationSchema, loading, onSubmit }) {
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => onSubmit(values)}>
			{({ handleSubmit, values, handleChange, handleBlur }) => (
				<Form>
					<div className="row">
						<div className="col-md-6">
							<FormGroup label="E-Posta" labelFor="email">
								<Textbox
									id="email"
									type="email"
									name="email"
									placeholder="E-Posta"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup label="Şifre" labelFor="password">
								<Textbox
									id="password"
									type="password"
									name="password"
									placeholder="Şifre"
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup label="Ad" labelFor="name">
								<Textbox
									id="name"
									type="text"
									name="name"
									placeholder="Ad"
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup label="Soyad" labelFor="surname">
								<Textbox
									id="surname"
									type="text"
									name="surname"
									placeholder="Soyad"
									value={values.surname}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup label="Telefon" labelFor="phone">
								<Textbox
									id="phone"
									type="text"
									name="phone"
									placeholder="Telefon"
									value={values.phone}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup label="T.C. Kimlik No" labelFor="identityNumber">
								<Textbox
									id="identityNumber"
									type="text"
									name="identityNumber"
									placeholder="T.C. Kimlik No"
									value={values.identityNumber}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-4">
							<FormGroup label="Durum">
								<Checkbox
									label="Aktif / Pasif"
									name="isActive"
									id="status"
									checked={values.isActive}
									onChange={handleChange}
								/>
							</FormGroup>
						</div>
						<div className="col-md-4">
							<FormGroup label="Onaylı mı?">
								<Checkbox
									label="Evet / Hayır"
									name="isApproved"
									id="isApproved"
									checked={values.isApproved}
									onChange={handleChange}
								/>
							</FormGroup>
						</div>
						<div className="col-md-4">
							<FormGroup label="İletişim izni">
								<Checkbox
									label="Var / Yok"
									name="contactPermission"
									id="contactPermission"
									checked={values.contactPermission}
									onChange={handleChange}
								/>
							</FormGroup>
						</div>
						<div className="col-md-12 text-end">
							<hr />
							<CancelButton link="/customers" />
							<SaveButton loading={loading} />
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

CustomerForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	validationSchema: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default CustomerForm
