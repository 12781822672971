import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import http from '../../utils/config/service'
import { ExhibitionSchema } from '../../utils/validations'

import { Formik, Form } from 'formik'

import Card from '../../components/shared/Card'
import Tab from '../../components/shared/Tab'
import FormGroup from '../../components/shared/FormGroup'
import Textbox from '../../components/shared/Textbox'
import RichText from '../../components/rich-text'
import Select from '../../components/select'
import FilePicker from '../../components/file-picker'
import Checkbox from '../../components/shared/Checkbox'
import CancelButton from '../../components/buttons/CancelButton'
import SaveButton from '../../components/buttons/SaveButton'

function ExhibitionForm({ initialValues, loading, onSubmit }) {
	const [works, setWorks] = useState([])

	const handleSubmit = (values) => {
		const payload = {
			name: values.name,
			description: values.description,
			isCurrent: values.isCurrent,
			order: values.order,
			image: values.image,
			works: values.works.map((work) => work.value),
			date: `${values.startDate} - ${values.endDate}`,
			translations: {
				en: {
					name: values.nameEn,
					description: values.descriptionEn
				}
			}
		}

		onSubmit(payload)
	}

	useEffect(() => {
		http
			.get('/works')
			.then(({ data }) => {
				const normalizedWorks = data.map((work) => ({ value: work._id, label: `${work.name} - ${work.artistId.name}` }))
				setWorks(normalizedWorks)
			})
			.catch((err) => console.log(err))
	}, [])

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={ExhibitionSchema}
			onSubmit={handleSubmit}>
			{({ values, handleChange, handleBlur, errors, touched }) => (
				<Form>
					<div className="row">
						<div className="col-md-8">
							<Card>
								<Tab>
									<Tab.Pane title="Genel">
										<Tab>
											<Tab.Pane title="Türkçe">
												<FormGroup label="Adı" labelFor="name">
													<Textbox
														type="text"
														name="name"
														placeholder="Sergi Adı"
														value={values.name}
														id="name"
														onChange={handleChange}
														onBlur={handleBlur}
													/>
												</FormGroup>
												<FormGroup label="Açıklama">
													<RichText
														name="description"
														placeholder="Sergi Açıklaması"
														errors={errors}
														touched={touched}
													/>
												</FormGroup>
											</Tab.Pane>
											<Tab.Pane title="İngilizce">
												<FormGroup label="Adı" labelFor="nameEn">
													<Textbox
														type="text"
														name="nameEn"
														placeholder="Exhibition Name"
														value={values.nameEn}
														id="nameEn"
														onChange={handleChange}
														onBlur={handleBlur}
													/>
												</FormGroup>
												<FormGroup label="Açıklama">
													<RichText
														name="descriptionEn"
														placeholder="Exhibition Description"
														errors={errors}
														touched={touched}
													/>
												</FormGroup>
											</Tab.Pane>
										</Tab>
									</Tab.Pane>
									<Tab.Pane title="Eserler">
										<Select name="works" isMulti options={works} errors={errors} touched={touched} />
									</Tab.Pane>
								</Tab>
							</Card>
						</div>
						<div className="col-md-4">
							<Card>
								<FormGroup label="Başlangıç Tarihi" labelFor="startDate">
									<Textbox
										type="date"
										name="startDate"
										value={values.startDate}
										id="startDate"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Bitiş Tarihi" labelFor="endDate">
									<Textbox
										type="date"
										name="endDate"
										value={values.endDate}
										id="endDate"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Gösterim Sırası" labelFor="order">
									<Textbox
										type="number"
										name="order"
										value={values.order}
										id="order"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Fotoğraf">
									<FilePicker name="image" value={values.image} />
								</FormGroup>
								<FormGroup label="Geçerli Mi?">
									<Checkbox
										label="Evet / Hayır"
										name="isCurrent"
										id="isCurrent"
										checked={values.isCurrent}
										onChange={handleChange}
									/>
								</FormGroup>
								<hr />
								<div className="text-end">
									<CancelButton link="/exhibitions" />
									<SaveButton loading={loading} />
								</div>
							</Card>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

ExhibitionForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default ExhibitionForm
