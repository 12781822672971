import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreateUserSchema } from '../../utils/validations'
import { toast } from 'react-toastify'

import http from '../../utils/config/service'

import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/shared/Card'
import UserForm from '../../components/forms/UserForm'

export function UserCreate() {
	const navigate = useNavigate()
	const [saveLoading, setSaveLoading] = useState(false)

	const initialValues = {
		email: '',
		password: '',
		rePassword: '',
		fullName: '',
		isActive: false
	}

	const handleSubmit = (values) => {
		setSaveLoading(true)
		delete values.rePassword

		http
			.post('/users', values)
			.then(() => {
				toast.success('Kullanıcı eklendi!')
				navigate('/users')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Yeni Yönetici">
			<Card>
				<UserForm
					initialValues={initialValues}
					validationSchema={CreateUserSchema}
					loading={saveLoading}
					onSubmit={handleSubmit}
				/>
			</Card>
		</PageLayout>
	)
}
