import PropTypes from 'prop-types'

function PageLayout({ children, title, subtitle, createSlot }) {
	return (
		<>
			<div className="page-heading">
				<div className="row">
					<div className="col-12 col-md-6">
						<h3>{title}</h3>
						{subtitle && <p className="text-subtitle text-muted">{subtitle}</p>}
					</div>
					{createSlot && <div className="col-12 col-md-6 text-md-end">{createSlot}</div>}
				</div>
			</div>
			<div className="page-container">{children}</div>
		</>
	)
}

PageLayout.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	createSlot: PropTypes.element
}

PageLayout.defaultProps = {
	title: 'Ana Sayfa',
	subtitle: ''
}

export default PageLayout
