import * as Yup from 'yup'

Yup.setLocale({
	mixed: {
		required: 'Bu alan boş geçilemez!',
		oneOf: 'Şifreler aynı değil!'
	},
	string: {
		email: 'Lütfen geçerli bir e-posta adresi giriniz!',
		min: 'Bu alan en az ${min} karakter olmalıdır!',
		max: 'Bu alan en az ${max} karakter olmalıdır!'
	},
	number: {
		min: 'Bu alan en az ${min} değerinde olmalıdır!'
	}
})

export default Yup
