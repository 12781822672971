import { useState, useEffect } from 'react'
import classNames from 'classnames'

import { useField, ErrorMessage } from 'formik'

import Button from '../shared/Button'
import Modal from '../Modal'
import Media from '../media'

function FilePicker({ imageHeight, ...props }) {
	const classes = classNames(['mt-2', { 'img-fluid': !!!imageHeight }])
	const [style, setStyle] = useState({})
	const [field, meta, helpers] = useField(props.name)
	const [selectedFilePath, setSelectedFilePath] = useState('')
	const [isModalShowing, setIsModalShowing] = useState(false)

	const handleSelecFile = (filePath) => {
		helpers.setValue(filePath)
		setSelectedFilePath(filePath)
		setIsModalShowing(false)
	}

	useEffect(() => {
		setSelectedFilePath(field.value)
		setStyle({
			objectFit: 'cover',
			width: '100%',
			height: `${imageHeight}px`
		})
	}, [field.value])

	return (
		<>
			<input type="hidden" {...field} {...props} />
			<Button
				type="button"
				block
				size="md"
				variant="light"
				icon="bi bi-card-image"
				styleName="justify-content-center"
				onClick={() => setIsModalShowing(!isModalShowing)}>
				Dosya Seç
			</Button>
			<ErrorMessage name={props.name} component="div" className="invalid-feedback d-block" />
			{!!selectedFilePath && <img src={selectedFilePath} alt="selected image" className={classes} style={style} />}
			<Modal isShowing={isModalShowing} size="lg" title="Dosya Seç" onClose={() => setIsModalShowing(false)}>
				<Media onSelectFile={handleSelecFile} />
			</Modal>
		</>
	)
}

export default FilePicker
