import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'
import { moment } from '../../utils/helpers/index'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import WorkshopSessionForm from '../../components/forms/WorkshopSessionForm'

export default function SessionEdit() {
	const { workshopId, id } = useParams()
	const navigate = useNavigate()

	const [requestLoader, setRequestLoader] = useState(true)
	const [initialValues, setInitialValues] = useState()
	const [saveLoading, setSaveLoading] = useState(false)
	const [workshop, setWorkshop] = useState({})

	useEffect(() => {
		http
			.get(`/ateliers/sessions/${id}`)
			.then(({ data }) => {
				setWorkshop(data.atelier)
				const time = data.time.split(' - ')
				setInitialValues({
					date: moment(data.date).format('Y-MM-DD'),
					startTime: time[0],
					endTime: time[1],
					quota: data.quota
				})
				setRequestLoader(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.patch(`/ateliers/update-session/${id}`, payload)
			.then(() => {
				toast.success('Seans düzenlendi!')
				navigate(`/workshops/${workshopId}/sessions`)
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title={!requestLoader ? workshop.title : '...'} subtitle="Seansı Düzenle">
			{requestLoader && <CardLoader />}
			{!requestLoader && (
				<WorkshopSessionForm
					initialValues={initialValues}
					loading={saveLoading}
					workshopId={workshop._id}
					onSubmit={handleSubmit}
				/>
			)}
		</PageLayout>
	)
}
