import Yup from './config'

export const PageSchema = Yup.object().shape({
	title: Yup.string().required(),
	content: Yup.string().required(),
	gallery: Yup.array().of(
		Yup.object().shape({
			image: Yup.string().required()
		})
	),
	titleEn: Yup.string(),
	contentEn: Yup.string()
})
