import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UpdateCustomerSchema } from '../../utils/validations'
import { toast } from 'react-toastify'

import http from '../../utils/config/service'

import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/shared/Card'
import CardLoader from '../../components/shared/CardLoader'
import CustomerForm from '../../components/forms/CustomerForm'

export function CustomerEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [cardLoading, setCardLoading] = useState(true)
	const [initialValues, setIntialValues] = useState({
		email: '',
		password: '',
		name: '',
		surname: '',
		isActive: false,
		isApproved: false,
		contactPermission: false,
		phone: '',
		identityNumber: ''
	})
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/customers/${id}`)
			.then(({ data }) => {
				delete data._id
				delete data.favorites
				delete data.createdAt
				delete data.updatedAt
				data.password = ''
				setIntialValues({ ...initialValues, ...data })
			})
			.catch(() => navigate('/customers'))
			.finally(() => setCardLoading(false))
	}, [])

	const handleSubmit = (values) => {
		setSaveLoading(true)

		if (!values.password) {
			delete values.password
		}

		http
			.patch(`/customers/${id}`, values)
			.then(() => {
				toast.success('Müşteri düzenlendi!')
				navigate('/customers')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Müşteriyi Düzenle">
			<Card>
				{cardLoading && <CardLoader />}
				{!cardLoading && (
					<CustomerForm
						initialValues={initialValues}
						validationSchema={UpdateCustomerSchema}
						loading={saveLoading}
						onSubmit={handleSubmit}
					/>
				)}
			</Card>
		</PageLayout>
	)
}
