import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import WorksopSessionForm from '../../components/forms/WorkshopSessionForm'

export default function WorkshopCreate() {
	const { workshopId } = useParams()
	const navigate = useNavigate()

	const [pending, setPending] = useState(true)
	const [workshop, setWorkshop] = useState({})

	useEffect(() => {
		http
			.get('/ateliers/' + workshopId)
			.then(({ data }) => {
				setPending(false)
				setWorkshop(data)
			})
			.catch((response) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
	}, [])

	const initialValues = {
		atelier: workshopId,
		date: '',
		startTime: '',
		endTime: '',
		quota: ''
	}

	const [saveLoading, setSaveLoading] = useState(false)

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.post('/ateliers/add-session', payload)
			.then(() => {
				toast.success('Seans eklendi!')
				navigate(`/workshops/${workshopId}/sessions`)
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title={!pending ? workshop.title : '...'} subtitle="Yeni Seans">
			<WorksopSessionForm
				initialValues={initialValues}
				loading={saveLoading}
				workshopId={workshopId}
				onSubmit={handleSubmit}
			/>
		</PageLayout>
	)
}
