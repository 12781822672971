import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'
import { dateRangeFormatter } from '../../utils/helpers'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CreateButton from '../../components/buttons/CreateButton'
import Card from '../../components/shared/Card'
import Table from '../../components/shared/Table'

const columns = [
	{
		name: 'Sergi',
		selector: (row) => row.name,
		sortable: true
	},
	{
		name: 'Tarih',
		selector: (row) => dateRangeFormatter(row.date),
		sortable: true
	},
	{
		name: 'Geçerli Mi?',
		selector: (row) =>
			row.isCurrent ? (
				<span className="badge bg-light-success">Evet</span>
			) : (
				<span className="badge bg-light-secondary">Hayır</span>
			),
		sortable: true
	},
	{
		name: 'Gösterim Sırası',
		selector: (row) => row.order,
		sortable: true
	}
]

export default function Exhibitions() {
	const navigate = useNavigate()

	const [exhibitions, setExhibitions] = useState([])
	const [pending, setPending] = useState(true)

	const fetchExhibition = () => {
		http
			.get('/exhibitions')
			.then(({ data }) => setExhibitions(data))
			.catch((err) => console.log(err))
			.finally(() => setPending(false))
	}

	const removeExhibition = (id) => {
		http
			.delete(`/exhibitions/${id}`)
			.then(() => {
				toast.success('Sergi silindi!')
				fetchExhibition()
			})
			.catch(({ response }) => {
				if (response.data?.showUser) {
					toast.error(response.data?.message)
				}
			})
	}

	const firedAction = ({ text, param }) => {
		switch (text) {
			case 'Düzenle':
				navigate(`edit/${param}`)
				break
			case 'Sil':
				removeExhibition(param)
				break
			default:
				break
		}
	}

	useEffect(() => {
		fetchExhibition()
	}, [])

	return (
		<PageLayout title="Sergiler" createSlot={<CreateButton text="Yeni Sergi" link="create" />}>
			<Card>
				<Table columns={columns} data={exhibitions} pending={pending} firedAction={firedAction} />
			</Card>
		</PageLayout>
	)
}
