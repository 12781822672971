import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import WorkshopForm from '../../components/forms/WorkshopForm'

export default function WorkshopEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [requestLoader, setRequestLoader] = useState(true)
	const [initialValues, setInitialValues] = useState()
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/ateliers/${id}`)
			.then(({ data }) => {
				setInitialValues({
					title: data.title,
					description: data.description,
					instructor: data.instructor,
					image: data.image,
					titleEn: data.translations.en.title,
					descriptionEn: data.translations.en.description,
					price: data.price,
					isCurrent: data.isCurrent,
					order: data.order
				})
				setRequestLoader(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.patch(`/ateliers/${id}`, payload)
			.then(() => {
				toast.success('Atölye düzenlendi!')
				navigate('/workshops')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Atölyeyi Düzenle">
			{requestLoader && <CardLoader />}
			{!requestLoader && <WorkshopForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />}
		</PageLayout>
	)
}
