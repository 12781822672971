import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { WorkSchema } from '../../utils/validations'

import http from '../../utils/config/service'

import { Formik, Form } from 'formik'

import Card from '../../components/shared/Card'
import Tab from '../../components/shared/Tab'
import FormGroup from '../../components/shared/FormGroup'
import Textbox from '../../components/shared/Textbox'
import RichText from '../../components/rich-text'
import Select from '../../components/select'
import Checkbox from '../../components/shared/Checkbox'
import NewImageButton from '../../components/buttons/NewImageButton'
import FilePicker from '../../components/file-picker'
import Button from '../../components/shared/Button'
import CancelButton from '../../components/buttons/CancelButton'
import SaveButton from '../../components/buttons/SaveButton'

function WorkForm({ initialValues, loading, onSubmit }) {
	const [artists, setArtists] = useState([])
	const [filters, setFilters] = useState([])
	const [currentValues, setCurrentValues] = useState(initialValues)

	const handleNewImage = (values) => {
		const gallery = [...values.gallery]
		gallery.push({ image: '' })
		setCurrentValues({
			...values,
			gallery
		})
	}

	const handleRemoveImage = (values, index) => {
		const gallery = values.gallery.filter((item, indx) => indx !== index)
		setCurrentValues({
			...values,
			gallery
		})
	}

	const fetchArtists = () => {
		http
			.get('/artists')
			.then(({ data }) => {
				const artisOptions = data.map((artist) => ({ value: artist._id, label: artist.name }))
				setArtists(artisOptions)
			})
			.catch((err) => console.log(err))
	}

	const fetchFilters = () => {
		http
			.get('/filters/items')
			.then(({ data }) => {
				const groupedFilters = []
				data.forEach((filter) => {
					const filterGroupIndex = groupedFilters.findIndex((group) => group.label === filter.groupId.name)
					if (filterGroupIndex > -1) {
						groupedFilters[filterGroupIndex].options.push({ value: filter._id, label: filter.name })
					} else {
						groupedFilters.push({
							label: filter.groupId.name,
							options: [{ value: filter._id, label: filter.name }]
						})
					}
				})
				setFilters(groupedFilters)
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		fetchArtists()
		fetchFilters()
	}, [])

	return (
		<Formik
			enableReinitialize
			initialValues={currentValues}
			validationSchema={WorkSchema}
			onSubmit={(values) => onSubmit(values)}>
			{({ values, handleChange, handleBlur, errors, touched }) => (
				<Form>
					<div className="row">
						<div className="col-md-8">
							<Card>
								<Tab>
									<Tab.Pane title="Genel">
										<Tab>
											<Tab.Pane title="Türkçe">
												<FormGroup label="Adı" labelFor="name">
													<Textbox
														type="text"
														name="name"
														placeholder="Eser Adı"
														value={values.name}
														id="name"
														onChange={handleChange}
														onBlur={handleBlur}
													/>
												</FormGroup>
												<FormGroup label="Açıklama">
													<RichText
														name="description"
														placeholder="Eser Açıklaması"
														errors={errors}
														touched={touched}
													/>
												</FormGroup>
											</Tab.Pane>
											<Tab.Pane title="İngilizce">
												<FormGroup label="Adı" labelFor="nameEn">
													<Textbox
														type="text"
														name="nameEn"
														placeholder="Work Name"
														value={values.nameEn}
														id="nameEn"
														onChange={handleChange}
														onBlur={handleBlur}
													/>
												</FormGroup>
												<FormGroup label="Açıklama">
													<RichText
														name="descriptionEn"
														placeholder="Work Descripition"
														errors={errors}
														touched={touched}
													/>
												</FormGroup>
											</Tab.Pane>
										</Tab>
									</Tab.Pane>
									<Tab.Pane title="Görseller">
										<div className="row">
											<div className="col-md-3 mb-4">
												<NewImageButton onClick={() => handleNewImage(values)} />
											</div>
											{values.gallery.length &&
												values.gallery.map((gallery, index) => (
													<div key={index} className="col-md-3 text-center mb-4">
														<FilePicker
															name={`gallery.${index}.image`}
															value={`gallery.${index}.image`}
															imageHeight="150"
														/>
														{index !== 0 && (
															<Button
																variant="danger"
																size="xs"
																icon="bi bi-trash3"
																onClick={() => handleRemoveImage(values, index)}>
																Sil
															</Button>
														)}
													</div>
												))}
										</div>
									</Tab.Pane>
									<Tab.Pane title="Filtreler">
										<Select name="filters" isMulti options={filters} errors={errors} touched={touched} />
									</Tab.Pane>
								</Tab>
							</Card>
						</div>
						<div className="col-md-4">
							<Card>
								<FormGroup label="Sanatçı">
									<Select name="artistId" options={artists} errors={errors} touched={touched} />
								</FormGroup>
								<FormGroup label="Stok Kodu" labelFor="stockc-code">
									<Textbox
										name="stockCode"
										id="stock-code"
										placeholder="Ör: XC1001"
										value={values.stockCode}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Stok Miktarı" labelFor="stock">
									<Textbox
										type="number"
										name="stock"
										id="stock"
										placeholder="Ör: 10"
										value={values.stock}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Fiyat" labelFor="price">
									<Textbox
										type="number"
										name="price"
										id="price"
										placeholder="Ör: 10000"
										value={values.price}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="İndirimli Fiyat" labelFor="discountedPrice">
									<Textbox
										type="number"
										name="discountedPrice"
										id="discountedPrice"
										placeholder="Ör: 9000"
										value={values.discountedPrice}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Kargo Ücreti" labelFor="shippingPrice">
									<Textbox
										type="number"
										name="shippingPrice"
										id="shippingPrice"
										placeholder="Ör: 15"
										value={values.shippingPrice}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="K.D.V" labelFor="hasVat">
									<Checkbox
										label="Dahil / Dahil Değil"
										name="hasVat"
										id="hasVat"
										checked={values.hasVat}
										onChange={handleChange}
									/>
								</FormGroup>
								{values.hasVat && (
									<FormGroup label="K.D.V Oranı" labelFor="vatRate">
										<Textbox
											type="number"
											name="vatRate"
											id="vatRate"
											placeholder="Ör: 18"
											value={values.vatRate}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									</FormGroup>
								)}
								<FormGroup label="Durum" labelFor="status">
									<Checkbox
										label="Aktif / Pasif"
										name="status"
										id="status"
										checked={values.status}
										onChange={handleChange}
									/>
								</FormGroup>
								<hr />
								<div className="text-end">
									<CancelButton link="/works" />
									<SaveButton loading={loading} />
								</div>
							</Card>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

WorkForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default WorkForm
