import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PageSchema } from '../../utils/validations'

import http from '../../utils/config/service'

import { Formik, Form } from 'formik'

import Card from '../../components/shared/Card'
import Tab from '../../components/shared/Tab'
import FormGroup from '../../components/shared/FormGroup'
import Textbox from '../../components/shared/Textbox'
import RichText from '../../components/rich-text'
import NewImageButton from '../../components/buttons/NewImageButton'
import FilePicker from '../../components/file-picker'
import Button from '../../components/shared/Button'
import CancelButton from '../../components/buttons/CancelButton'
import SaveButton from '../../components/buttons/SaveButton'

function WorkForm({ initialValues, loading, onSubmit }) {
	const [currentValues, setCurrentValues] = useState(initialValues)

	const handleNewImage = (values) => {
		const gallery = [...values.gallery]
		gallery.push({ image: '' })
		setCurrentValues({
			...values,
			gallery
		})
	}

	const handleRemoveImage = (values, index) => {
		const gallery = values.gallery.filter((item, indx) => indx !== index)
		setCurrentValues({
			...values,
			gallery
		})
	}

	const handleSubmit = (values) => {
		const payload = {
			...values,
			gallery: values.gallery.map((item) => item.image),
			translations: {
				en: {
					title: values.titleEn,
					content: values.contentEn
				}
			}
		}
		delete payload.titleEn
		delete payload.contentEn

		onSubmit(payload)
	}

	return (
		<Formik enableReinitialize initialValues={currentValues} validationSchema={PageSchema} onSubmit={handleSubmit}>
			{({ values, handleChange, handleBlur, errors, touched }) => (
				<Form>
					<Card>
						<Tab>
							<Tab.Pane title="Genel">
								<Tab>
									<Tab.Pane title="Türkçe">
										<FormGroup label="Başlık" labelFor="title">
											<Textbox
												type="text"
												name="title"
												placeholder="Sayfa başlığı"
												value={values.title}
												id="title"
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</FormGroup>
										<FormGroup label="İçerik">
											<RichText name="content" placeholder="Sayfa içeriği" errors={errors} touched={touched} />
										</FormGroup>
									</Tab.Pane>
									<Tab.Pane title="İngilizce">
										<FormGroup label="Başlık" labelFor="titleEn">
											<Textbox
												type="text"
												name="titleEn"
												placeholder="Page title"
												value={values.titleEn}
												id="titleEn"
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</FormGroup>
										<FormGroup label="İçerik">
											<RichText name="contentEn" placeholder="Page content" errors={errors} touched={touched} />
										</FormGroup>
									</Tab.Pane>
								</Tab>
							</Tab.Pane>
							<Tab.Pane title="Görseller">
								<div className="row">
									<div className="col-md-3 mb-4">
										<NewImageButton onClick={() => handleNewImage(values)} />
									</div>
									{values.gallery.length > 0 &&
										values.gallery.map((gallery, index) => (
											<div key={index} className="col-md-3 text-center mb-4">
												<FilePicker
													name={`gallery.${index}.image`}
													value={`gallery.${index}.image`}
													imageHeight="150"
												/>
												<Button
													variant="danger"
													size="xs"
													icon="bi bi-trash3"
													onClick={() => handleRemoveImage(values, index)}>
													Sil
												</Button>
											</div>
										))}
								</div>
							</Tab.Pane>
						</Tab>
						<hr />
						<div className="text-end">
							<CancelButton link="/pages" />
							<SaveButton loading={loading} />
						</div>
					</Card>
				</Form>
			)}
		</Formik>
	)
}

WorkForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default WorkForm
