import Button from '../shared/Button'

function CreateButton({ text, link, ...props }) {
	return (
		<Button variant="primary" link={link} icon="bi bi-plus-circle" {...props}>
			{text}
		</Button>
	)
}

export default CreateButton
