import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import FilterGroupForm from '../../components/forms/FilterGroupForm'

export function FilterGroupCreate() {
	const navigate = useNavigate()

	const initialValues = {
		name: '',
		nameEn: ''
	}

	const [saveLoading, setSaveLoading] = useState(false)

	const handleSubmit = (values) => {
		setSaveLoading(true)

		const payload = {
			...values,
			translations: {
				en: {
					name: values.nameEn
				}
			}
		}
		delete payload.nameEn

		http
			.post('/filters', payload)
			.then(() => {
				toast.success('Filtre grubu eklendi!')
				navigate('/filter-groups')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Yeni Filtre Grubu">
			<FilterGroupForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />
		</PageLayout>
	)
}
