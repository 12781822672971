import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { FilterGroupSchema } from '../../utils/validations'

import http from '../../utils/config/service'

import { Formik, Form } from 'formik'

import Card from '../../components/shared/Card'
import Tab from '../../components/shared/Tab'
import FormGroup from '../../components/shared/FormGroup'
import Textbox from '../../components/shared/Textbox'
import Select from '../../components/select'
import CancelButton from '../../components/buttons/CancelButton'
import SaveButton from '../../components/buttons/SaveButton'

function FilterItemForm({ initialValues, loading, onSubmit }) {
	const [groups, setGroups] = useState([])

	useEffect(() => {
		http
			.get('/filters')
			.then(({ data }) => {
				const normalizedData = data.map((item) => ({ value: item._id, label: item.name }))
				setGroups(normalizedData)
			})
			.catch((err) => console.log(err))
	}, [])

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={FilterGroupSchema}
			onSubmit={(values) => onSubmit(values)}>
			{({ handleSubmit, values, handleChange, handleBlur, errors, touched }) => (
				<Form>
					<div className="row">
						<div className="col-md-8">
							<Card>
								<FormGroup label="Filtre Adı">
									<Tab tabNavSpace="1">
										<Tab.Pane title="Türkçe">
											<Textbox
												type="text"
												name="name"
												placeholder="Filtre Adı"
												value={values.name}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Tab.Pane>
										<Tab.Pane title="İngilizce">
											<Textbox
												type="text"
												name="nameEn"
												placeholder="Filter Name"
												value={values.nameEn}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Tab.Pane>
									</Tab>
								</FormGroup>
							</Card>
						</div>
						<div className="col-md-4">
							<Card>
								<FormGroup label="Filtre Grubu">
									<Select name="groupId" options={groups} errors={errors} touched={touched} />
								</FormGroup>
								<hr />
								<div className="text-end">
									<CancelButton link="/filters" />
									<SaveButton loading={loading} />
								</div>
							</Card>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

FilterItemForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default FilterItemForm
