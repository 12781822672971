import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'

import { moment } from '../../utils/helpers/index'
import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CreateButton from '../../components/buttons/CreateButton'
import Card from '../../components/shared/Card'
import Table from '../../components/shared/Table'

const columns = [
	{
		name: 'Tarih',
		cell: (row) => moment(row.date).format('DD.MM.Y'),
		selector: (row) => row.date,
		sortable: true
	},
	{
		name: 'Saat',
		selector: (row) => row.time,
		sortable: true
	},
	{
		name: 'Kontenjan',
		selector: (row) => row.quota,
		sortable: true
	},
	{
		name: 'Katılımcı Sayısı',
		selector: (row) => row.participantCount,
		sortable: true
	}
]

export default function Sessions() {
	const { workshopId } = useParams()
	const navigate = useNavigate()

	const [sssions, setSessions] = useState([])
	const [pending, setPending] = useState(true)
	const [workshop, setWorkshop] = useState({})

	const actions = [{ icon: 'bi bi-people', text: 'Katılımcılar' }]

	const fetchWorkshop = async () => {
		await http
			.get('/ateliers/' + workshopId)
			.then(({ data }) => setWorkshop(data))
			.catch((response) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
	}

	const fetchSessions = () => {
		http
			.get(`/ateliers/${workshopId}/sessions`)
			.then(({ data }) => setSessions(data))
			.catch((err) => console.log(err))
			.finally(() => setPending(false))
	}

	const removeSession = (id) => {
		http
			.delete(`/ateliers/delete-session/${id}`)
			.then(() => {
				toast.success('Seans silindi!')
				fetchSessions()
			})
			.catch(({ response }) => {
				if (response.data?.showUser) {
					toast.error(response.data?.message)
				}
			})
	}

	const firedAction = ({ text, param }) => {
		switch (text) {
			case 'Katılımcılar':
				navigate(`/workshops/${workshop._id}/participants?session=${param}`)
				break

			case 'Düzenle':
				navigate(`edit/${param}`)
				break

			case 'Sil':
				removeSession(param)
				break

			default:
				break
		}
	}

	useEffect(() => {
		fetchWorkshop()
		fetchSessions()
	}, [])

	return (
		<PageLayout
			title={!pending ? workshop.title : '...'}
			subtitle="Seanslar"
			createSlot={<CreateButton text="Yeni Seans" link="create" />}>
			<Card>
				<Table columns={columns} data={sssions} pending={pending} actions={actions} firedAction={firedAction} />
			</Card>
		</PageLayout>
	)
}
