import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FieldSchema } from '../../utils/validations'

import http from '../../utils/config/service'

import { Formik, Form } from 'formik'

import Card from '../shared/Card'
import FormGroup from '../shared/FormGroup'
import Textbox from '../shared/Textbox'
import Select from '../../components/select'
import CancelButton from '../buttons/CancelButton'
import SaveButton from '../buttons/SaveButton'

function FieldForm({ initialValues, loading, onSubmit }) {
	const [refType, setRefType] = useState(initialValues.refType)
	const [items, setItems] = useState([])

	const fetchData = () => {
		if (refType === 'work') {
			http.get('/works').then(({ data }) => {
				setItems(data.map((item) => ({ value: item._id, label: `${item.name} - ${item.stockCode}` })))
			})
			return
		}

		http.get('/artists').then(({ data }) => {
			setItems(data.map((item) => ({ value: item._id, label: item.name })))
		})
	}

	useEffect(() => {
		fetchData()
	}, [refType])

	const handleSubmit = (values) => {
		const payload = {
			...values,
			items: values.items.map((item) => item.value),
			refType: refType
		}

		onSubmit(payload)
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={FieldSchema}
			onSubmit={(values) => handleSubmit(values)}>
			{({ values, handleChange, handleBlur, errors, touched }) => (
				<Form>
					<Card>
						<FormGroup label="Başlık" labelFor="title">
							<Textbox
								type="text"
								name="title"
								placeholder="Alan Başlığı"
								value={values.title}
								id="title"
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</FormGroup>
						<FormGroup label="Kod" labelFor="key" topText="Türkçe harf içeremez ve kelimeler tire (-) ile ayrılmalıdır">
							<Textbox
								type="text"
								name="key"
								placeholder="Alan Kodu"
								value={values.key}
								id="key"
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</FormGroup>
						<FormGroup label="Veri Türü" labelFor="refType">
							<select
								className="form-select"
								id="refType"
								value={refType}
								onChange={({ target }) => setRefType(target.value)}>
								<option value="work">Eser</option>
								<option value="artist">Sanatçı</option>
							</select>
						</FormGroup>
						<FormGroup label="İçerikler">
							<Select name="items" isMulti options={items} errors={errors} touched={touched} />
						</FormGroup>
						<hr />
						<div className="text-end">
							<CancelButton link="/fields" />
							<SaveButton loading={loading} />
						</div>
					</Card>
				</Form>
			)}
		</Formik>
	)
}

FieldForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default FieldForm
