import { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Field } from 'formik'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

function RichText({ name, placeholder, errors, touched }) {
	const quillRef = useRef()

	const imageHandler = () => {
		const url = prompt('Lütfen görsel adresini girin:')
		if (url) {
			const quill = quillRef.current.getEditor()
			const range = quill.getSelection()
			quill.editor.insertEmbed(range.index, 'image', url)
		}
	}

	const modules = useMemo(
		() => ({
			toolbar: {
				container: [
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline', 'strike'],
					['blockquote'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					[{ indent: '-1' }, { indent: '+1' }],
					[{ align: [] }],
					['link', 'image'],
					['clean']
				],
				handlers: {
					image: imageHandler
				}
			}
		}),
		[]
	)

	return (
		<>
			<Field name={name}>
				{({ field }) => (
					<ReactQuill
						ref={quillRef}
						theme="snow"
						modules={modules}
						className={`rich-editor ${errors[name] && touched[name] ? 'is-invalid' : ''}`}
						value={field.value}
						placeholder={placeholder}
						onChange={field.onChange(field.name)}
					/>
				)}
			</Field>
			{errors[name] && touched[name] ? <div className="invalid-feedback d-block">{errors[name]}</div> : null}
		</>
	)
}

RichText.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired
}

export default RichText
