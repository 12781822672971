import PropTypes from 'prop-types'

import { Formik, Form } from 'formik'

import FormGroup from '../../components/shared/FormGroup'
import Textbox from '../../components/shared/Textbox'
import Checkbox from '../../components/shared/Checkbox'
import CancelButton from '../../components/buttons/CancelButton'
import SaveButton from '../../components/buttons/SaveButton'

function UserForm({ initialValues, validationSchema, loading, onSubmit }) {
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => onSubmit(values)}>
			{({ handleSubmit, values, handleChange, handleBlur }) => (
				<Form>
					<div className="row">
						<div className="col-md-6">
							<FormGroup label="E-Posta" labelFor="email">
								<Textbox
									id="email"
									type="email"
									name="email"
									placeholder="E-Posta"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup label="İsim" labelFor="fullName">
								<Textbox
									id="fullName"
									type="text"
									name="fullName"
									placeholder="Ad Soyad"
									value={values.fullName}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup label="Şifre" labelFor="password">
								<Textbox
									id="password"
									type="password"
									name="password"
									placeholder="Şifre"
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup label="Tekrar Şifre" labelFor="rePassword">
								<Textbox
									id="rePassword"
									type="password"
									name="rePassword"
									placeholder="Şifreyi Doğrulayın"
									value={values.rePassword}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup label="Durum">
								<Checkbox
									label="Aktif / Pasif"
									name="isActive"
									id="status"
									checked={values.isActive}
									onChange={handleChange}
								/>
							</FormGroup>
						</div>
						<div className="col-md-12 text-end">
							<hr />
							<CancelButton link="/users" />
							<SaveButton loading={loading} />
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

UserForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	validationSchema: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default UserForm
