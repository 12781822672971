import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'
import classNames from 'classnames'

function Button({ children, link, type, variant, outline, size, block, rounded, styleName, loading, icon, ...props }) {
	const styles = classNames(
		'btn',
		`btn-${size}`,
		{
			[`btn-${variant}`]: !outline,
			[`btn-outline-${variant}`]: outline,
			'btn-block': block,
			'd-inline-flex gap-2': icon,
			'rounded-pill': rounded
		},
		styleName
	)

	return (
		<>
			{!!link && (
				<NavLink to={link} className={styles} {...props}>
					{icon && (
						<span style={{ marginTop: -1 }}>
							<i className={icon}></i>
						</span>
					)}
					{children}
				</NavLink>
			)}
			{!!!link && (
				<button type={type} className={styles} disabled={loading} {...props}>
					{loading && (
						<>
							<span className="spinner-grow spinner-grow-sm"></span> Yükleniyor...
						</>
					)}
					{!loading && (
						<>
							{icon && (
								<span style={{ marginTop: -1 }}>
									<i className={icon}></i>
								</span>
							)}
							{children}
						</>
					)}
				</button>
			)}
		</>
	)
}

Button.propTypes = {
	type: PropTypes.string,
	link: PropTypes.string,
	variant: PropTypes.string,
	outline: PropTypes.bool,
	size: PropTypes.string,
	block: PropTypes.bool,
	rounded: PropTypes.bool,
	styleName: PropTypes.string,
	loading: PropTypes.bool,
	icon: PropTypes.string
}

Button.defaultProps = {
	type: 'button',
	link: '',
	variant: 'primary',
	outline: false,
	size: 'sm',
	block: false,
	rounded: false,
	styleName: '',
	loading: false,
	icon: ''
}

export default Button
