import PropTypes from 'prop-types'

import { SeansSchema } from '../../utils/validations'

import { Formik, Form } from 'formik'

import Card from '../shared/Card'
import FormGroup from '../shared/FormGroup'
import Textbox from '../shared/Textbox'
import CancelButton from '../buttons/CancelButton'
import SaveButton from '../buttons/SaveButton'

function WorkshopSessionForm({ initialValues, loading, workshopId, onSubmit }) {
	const handleSubmit = (values) => {
		const payload = {
			atelier: values.atelier,
			date: values.date,
			time: `${values.startTime} - ${values.endTime}`,
			quota: values.quota
		}

		onSubmit(payload)
	}

	return (
		<Formik enableReinitialize initialValues={initialValues} validationSchema={SeansSchema} onSubmit={handleSubmit}>
			{({ values, handleChange, handleBlur, errors, touched }) => (
				<Form>
					<Card>
						<div className="row">
							<div className="col-md-3">
								<FormGroup label="Tarih" labelFor="date">
									<Textbox
										type="date"
										name="date"
										value={values.date}
										id="date"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
							</div>
							<div className="col-md-3">
								<FormGroup label="Başlangıç Saati" labelFor="startTime">
									<Textbox
										type="time"
										name="startTime"
										value={values.startTime}
										id="startTime"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
							</div>
							<div className="col-md-3">
								<FormGroup label="Bitiş Saati" labelFor="endTime">
									<Textbox
										type="time"
										name="endTime"
										value={values.endTime}
										id="endTime"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
							</div>
							<div className="col-md-3">
								<FormGroup label="Kontenjan" labelFor="quota">
									<Textbox
										type="number"
										name="quota"
										placeholder="Kontenjan"
										value={values.quota}
										id="quota"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
							</div>
						</div>
						<hr />
						<div className="text-end">
							<CancelButton link={`/workshops/${workshopId}/sessions`} />
							<SaveButton loading={loading} />
						</div>
					</Card>
				</Form>
			)}
		</Formik>
	)
}

WorkshopSessionForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default WorkshopSessionForm
