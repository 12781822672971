import { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import http from '../../utils/config/service'

import Button from '../shared/Button'
import Modal from '../Modal'
import CardLoader from '../shared/CardLoader'

import './style.css'

const re = new RegExp(/([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/)
const basePath = 'public'

function Media({ onSelectFile }) {
	const [path, setPath] = useState('/')
	const [content, setContent] = useState({})
	const [filteredContent, setFilteredContent] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const [isNewFolderSowing, setIsNewFolderShowing] = useState(false)
	const [newFolderName, setNewFolderName] = useState('')
	const [isUploadFileShowing, setIsUploadFileShowing] = useState(false)
	const [uploadLoading, setUploadLoading] = useState(false)

	const onOpeneDir = (data) => {
		setContent(data)
		setFilteredContent(data)
		setSelectedItems([])
	}

	const openDir = (dir = basePath) => {
		http
			.post('/media/open-folder', { dir })
			.then(({ data }) => {
				onOpeneDir(data)
				setPath(dir)
			})
			.catch((err) => console.log(err))
	}

	const goParent = () => {
		const currentPath = path.split('/')
		currentPath.pop()
		const dir = currentPath.join('/')
		openDir(dir)
	}

	const selectItem = (path, type = 'folder') => {
		const foundItem = selectedItems.find((item) => item.path === path)
		if (foundItem) {
			setSelectedItems(selectedItems.filter((item) => item.path !== path))
			return
		}

		setSelectedItems([...selectedItems, { path, type }])
	}

	const search = ({ target }) => {
		if (!target.value) {
			setFilteredContent(content)
			return
		}

		const result = {
			folders: content.folders.filter((folder) => folder.name.includes(target.value)),
			files: content.files.filter((folder) => folder.name.includes(target.value))
		}
		setFilteredContent(result)
	}

	const removeItem = () => {
		const selectedFolders = selectedItems.filter((folder) => folder.type === 'folder')
		const selectedFiles = selectedItems.filter((file) => file.type === 'file')

		if (selectedFolders.length) {
			removeFolder(selectedFolders.map((folder) => folder.path))
		}

		if (selectedFiles.length) {
			removeFile(selectedFiles.map((file) => file.path))
		}
	}

	const removeFolder = (dirs) => {
		http
			.post('media/remove-folder', { dirs })
			.then(({ data }) => onOpeneDir(data))
			.catch((err) => console.log(err))
	}

	const removeFile = (fileDirs) => {
		http
			.post('media/remove-file', { fileDirs })
			.then(({ data }) => onOpeneDir(data))
			.catch((err) => console.log(err))
	}

	const onCloseNewFolderModal = () => {
		setIsNewFolderShowing(false)
		setNewFolderName('')
	}

	const onCloseUploadFileModal = () => {
		setUploadLoading(false)
		setIsUploadFileShowing(false)
	}

	const createFolder = () => {
		http
			.post('media/create-folder', { dir: `${path}/${newFolderName}` })
			.then(({ data }) => {
				onCloseNewFolderModal()
				onOpeneDir(data)
			})
			.catch((err) => console.log(err))
	}

	const NewFolderModalFooter = () => {
		return (
			<Button variant="success" icon="bi bi-folder-plus" onClick={createFolder} disabled={!newFolderName}>
				Oluştur
			</Button>
		)
	}

	const onDrop = useCallback(
		(acceptedFiles) => {
			setUploadLoading(true)
			const formData = new FormData()
			formData.append('dir', path)
			acceptedFiles.forEach((file) => {
				formData.append('files', file)
			})
			http
				.post('media/upload-files', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(({ data }) => {
					onCloseUploadFileModal()
					onOpeneDir(data)
				})
				.catch((err) => console.log(err))
		},
		[path]
	)

	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	const handleSelectFile = (filePath) => {
		onSelectFile(`${process.env.REACT_APP_MEDIA_URL}/${filePath}`)
	}

	useEffect(() => {
		openDir()
	}, [])

	return (
		<>
			<div className="media">
				<div className="media__header">
					<div className="row">
						<div className="col-md-8">
							<div className="d-flex gap-1">
								<Button
									variant="primary"
									icon="bi bi-house"
									size="md"
									title="Ana Klasör"
									disabled={path === basePath}
									onClick={() => openDir()}
								/>
								<Button
									variant="secondary"
									icon="bi bi-arrow-90deg-up"
									size="md"
									title="Üst Klasör"
									disabled={path === basePath}
									onClick={goParent}
								/>
								<Button
									variant="success"
									icon="bi bi-arrow-clockwise"
									size="md"
									title="Yenile"
									onClick={() => openDir(path)}
								/>
								<input type="text" value={path} disabled="disabled" className="form-control flex-fill" />
								<Button
									variant="warning"
									icon="bi bi-folder-plus"
									size="md"
									title="Yeni Klasör"
									disabled={!content}
									onClick={() => setIsNewFolderShowing(true)}
								/>
								<Button
									variant="danger"
									icon="bi bi-trash"
									size="md"
									title="Sil"
									disabled={!selectedItems.length}
									onClick={removeItem}
								/>
								<Button
									variant="info"
									icon="bi bi-cloud-arrow-up"
									size="md"
									title="Dosya Yükle"
									disabled={!content}
									onClick={() => setIsUploadFileShowing(true)}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<input type="text" placeholder="Dosya yada klasör ara" className="form-control" onChange={search} />
						</div>
					</div>
				</div>
				<div className="media__body">
					<div className="row">
						{filteredContent.folders &&
							filteredContent.folders.map((folder, index) => (
								<div key={index} className="col-md-2 col-3">
									<div className="media__item">
										<button
											type="button"
											className="media__item-button media__item-button--folder"
											onClick={() => openDir(folder.path)}>
											<img
												src="/assets/images/folder.png"
												alt={folder.name}
												className="img-fluid"
												title={folder.name}
											/>
										</button>
										<label className="media__item-checkbox">
											<input
												type="checkbox"
												value={folder.path}
												checked={!!selectedItems.find((item) => item.path === folder.path)}
												onChange={({ target }) => selectItem(target.value)}
											/>
											<span className="media__item-name" title={folder.name}>
												{folder.name}
											</span>
										</label>
									</div>
								</div>
							))}
						{filteredContent.files &&
							filteredContent.files.map((file, index) => (
								<div key={index} className="col-md-2 col-3">
									<div className="media__item">
										<button
											type="button"
											className="media__item-button media__item-button--file"
											onClick={() => handleSelectFile(file.path)}>
											{re.test(file.name) && (
												<img
													src={`${process.env.REACT_APP_MEDIA_URL}/${file.path}`}
													alt={file.name}
													className="aspect-img"
													title={file.name}
												/>
											)}
											{!re.test(file.name) && (
												<img src="/assets/images/file.png" alt={file.name} className="img-fluid" title={file.name} />
											)}
										</button>
										<label className="media__item-checkbox">
											<input
												type="checkbox"
												value={file.path}
												checked={!!selectedItems.find((item) => item.path === file.path)}
												onChange={({ target }) => selectItem(target.value, 'file')}
											/>
											<span className="media__item-name" title={file.name}>
												{file.name}
											</span>
										</label>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
			<Modal
				size="sm"
				isShowing={isNewFolderSowing}
				title="Yeni Klasör"
				footer={<NewFolderModalFooter />}
				onClose={onCloseNewFolderModal}>
				<input
					type="text"
					className="form-control"
					placeholder="Klasör adı"
					value={newFolderName}
					onChange={({ target }) => setNewFolderName(target.value)}
				/>
			</Modal>
			<Modal isShowing={isUploadFileShowing} title="Dosya Yükle" onClose={onCloseUploadFileModal}>
				<div {...getRootProps({ className: 'dropzone' })}>
					<input {...getInputProps()} />
					{uploadLoading ? (
						<CardLoader height={50} loaderSize={30} />
					) : (
						<span>Dosyaları bu alana sürükleyin ve bırakın</span>
					)}
				</div>
			</Modal>
		</>
	)
}

export default Media
