import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useField, ErrorMessage } from 'formik'

function Textbox({ size, ...props }) {
	const [field, meta, helpers] = useField(props.name)
	const styles = classNames('form-control', {
		[`form-control-${size}`]: !!size,
		'is-invalid': meta.error && meta.touched
	})

	return (
		<>
			<input {...field} {...props} className={styles} />
			<ErrorMessage name={props.name} component="div" className="invalid-feedback" />
		</>
	)
}

Textbox.propTypes = {
	size: PropTypes.string
}

Textbox.defaultProps = {
	size: ''
}

export default Textbox
