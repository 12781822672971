import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Formik, Form } from 'formik'
import { LoginSchema } from '../../utils/validations/'

import './style.css'

import http from '../../utils/config/service'

import Button from '../../components/shared/Button'
import FormGroup from '../../components/shared/FormGroup'
import Textbox from '../../components/shared/Textbox'

function Login() {
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()
	const initialValues = { email: '', password: '' }

	const handleSubmit = (values) => {
		setIsLoading(true)
		http
			.post('/users/login', values)
			.then(({ data }) => {
				dispatch({ type: 'SET_USER', payload: data })
				localStorage.setItem('accessToken', data.accessToken)
				localStorage.setItem('refreshToken', data.refreshToken)
				navigate('/')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<div id="auth">
			<div className="row h-100">
				<div className="col-lg-5 col-12">
					<div id="auth-left">
						<div className="auth-logo">
							<Link to="/login">
								<img src="logo512.png" alt="Logo" />
							</Link>
						</div>
						<h1 className="auth-title mb-5">Giriş Yap</h1>
						<Formik
							initialValues={initialValues}
							validationSchema={LoginSchema}
							onSubmit={(values) => handleSubmit(values)}>
							{({ handleSubmit, values, handleChange, handleBlur }) => (
								<Form>
									<FormGroup icon="bi bi-person" styleName="mb-4">
										<Textbox
											type="email"
											name="email"
											placeholder="E-Posta"
											size="xl"
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									</FormGroup>
									<FormGroup icon="bi bi-shield-lock" styleName="mb-4">
										<Textbox
											type="password"
											name="password"
											placeholder="Şifre"
											size="xl"
											value={values.password}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									</FormGroup>
									<Button type="submit" size="lg" block loading={isLoading} styleName="shadow-lg mt-5">
										Giriş
									</Button>
								</Form>
							)}
						</Formik>
					</div>
				</div>
				<div className="col-lg-7 d-none d-lg-block">
					<div id="auth-right" />
				</div>
			</div>
		</div>
	)
}

export default Login
