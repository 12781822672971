import axios from 'axios'

const http = () => {
	let instance = axios.create({
		baseURL: process.env.REACT_APP_API_URL
	})

	instance.interceptors.request.use((config) => {
		const accessToken = localStorage.getItem('accessToken')
		config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : ''
		return config
	})

	return instance
}

export default http()
