function Footer() {
	const year = new Date().getFullYear()

	return (
		<footer>
			<div className="footer clearfix mb-0 text-muted">
				<div className="float-start">
					<p>{year} &copy; Kun Art Space Web Panel v1.1</p>
				</div>
				<div className="float-end">
					<p>
						Crafted with{' '}
						<span className="text-danger">
							<i className="bi bi-heart" />
						</span>{' '}
						by{' '}
						<a href="https://azajans.com" target="_blank" rel="noreferrer">
							AZ Digital Creative Agency
						</a>
						<a href="https://ardaunsal.dev" className="d-none">
							ardaunsal.dev
						</a>
					</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer
