import PropTypes from 'prop-types'
import { FilterGroupSchema } from '../../utils/validations'

import { Formik, Form } from 'formik'

import Card from '../shared/Card'
import Tab from '../shared/Tab'
import FormGroup from '../shared/FormGroup'
import Textbox from '../shared/Textbox'
import CancelButton from '../buttons/CancelButton'
import SaveButton from '../buttons/SaveButton'

function FilterGroupFrom({ initialValues, loading, onSubmit }) {
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={FilterGroupSchema}
			onSubmit={(values) => onSubmit(values)}>
			{({ handleSubmit, values, handleChange, handleBlur }) => (
				<Form>
					<Card>
						<FormGroup label="Grup Adı">
							<Tab tabNavSpace="1">
								<Tab.Pane title="Türkçe">
									<Textbox
										type="text"
										name="name"
										placeholder="Grup Adı"
										value={values.name}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</Tab.Pane>
								<Tab.Pane title="İngilizce">
									<Textbox
										type="text"
										name="nameEn"
										placeholder="Group Name"
										value={values.nameEn}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</Tab.Pane>
							</Tab>
						</FormGroup>
						<hr />
						<div className="text-end">
							<CancelButton link="/filter-groups" />
							<SaveButton loading={loading} />
						</div>
					</Card>
				</Form>
			)}
		</Formik>
	)
}

FilterGroupFrom.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default FilterGroupFrom
