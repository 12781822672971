import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import http from '../../utils/config/service'

import Loader from '../../components/layout/Loader'
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'
import Footer from '../../components/layout/Footer'

import style from './style.module.css'

function DefaultLayout() {
	const navigate = useNavigate()
	const { user, hasLoader } = useSelector((selector) => selector)
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')
	const refreshToken = localStorage.getItem('refreshToken')

	useEffect(() => {
		if (!accessToken) {
			navigate('/login')
			return
		}

		if (!user) {
			http
				.get('/users/get-info')
				.then(({ data }) => {
					const payload = {
						...data,
						accessToken,
						refreshToken
					}
					dispatch({ type: 'SET_USER', payload })
					dispatch({ type: 'SET_HAS_LOADER', payload: false })
				})
				.catch(() => {
					localStorage.removeItem('accessToken')
					localStorage.removeItem('refreshToken')
					navigate('/login')
				})
			return
		}

		dispatch({ type: 'SET_HAS_LOADER', payload: false })
	}, [])

	return (
		<>
			{hasLoader && <Loader />}
			<div id="app">
				<Sidebar />
				<div id="main" className={style.main}>
					<Header />
					<div className={style.content}>
						<Outlet />
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}

export default DefaultLayout
