import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import TemplateForm from '../../components/forms/TemplateForm'

export default function TemplateEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [requestLoader, setRequestLoader] = useState(true)
	const [initialValues, setInitialValues] = useState({
		name: '',
		key: '',
		value: '',
		valueEn: ''
	})
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/definitions/${id}`)
			.then(({ data }) => {
				setInitialValues({
					name: data.name,
					key: data.key,
					value: data.value,
					valueEn: data.translations.en.value
				})
				setRequestLoader(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.patch(`/definitions/${id}`, payload)
			.then(() => {
				toast.success('Şablon düzenlendi!')
				navigate('/templates')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Şablonu Düzenle">
			{requestLoader && <CardLoader />}
			{!requestLoader && <TemplateForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />}
		</PageLayout>
	)
}
