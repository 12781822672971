import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'
import { currency } from '../../utils/helpers'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CreateButton from '../../components/buttons/CreateButton'
import Card from '../../components/shared/Card'
import Table from '../../components/shared/Table'

const columns = [
	{
		name: 'Atölye',
		selector: (row) => row.title,
		sortable: true
	},
	{
		name: 'Eğitmen',
		selector: (row) => row.instructor,
		sortable: true
	},
	{
		name: 'Seans Sayısı',
		selector: (row) => row.sessionCount,
		sortable: true
	},
	{
		name: 'Katılımcı Sayısı',
		selector: (row) => row.participantCount,
		sortable: true
	},
	{
		name: 'Fiyat',
		selector: (row) => currency(row.price),
		sortable: true
	},
	{
		name: 'Geçerli Mi?',
		cell: (row) =>
			row.isCurrent ? (
				<span className="badge bg-light-success">Evet</span>
			) : (
				<span className="badge bg-light-secondary">Hayır</span>
			),
		selector: (row) => row.isCurrent,
		sortable: true
	},
	{
		name: 'Gösterim Sırası',
		selector: (row) => row.order,
		sortable: true
	}
]

export default function Workshops() {
	const navigate = useNavigate()

	const [workshops, setWorkshops] = useState([])
	const [pending, setPending] = useState(true)

	const actions = [
		{ icon: 'bi bi-calendar-event', text: 'Seanslar' },
		{ icon: 'bi bi-people', text: 'Katılımcılar' }
	]

	const fetchWorkshops = () => {
		http
			.get('/ateliers')
			.then(({ data }) => setWorkshops(data))
			.catch((err) => console.log(err))
			.finally(() => setPending(false))
	}

	const removeWorkshop = (id) => {
		http
			.delete(`/ateliers/${id}`)
			.then(() => {
				toast.success('Atölye silindi!')
				fetchWorkshops()
			})
			.catch(({ response }) => {
				if (response.data?.showUser) {
					toast.error(response.data?.message)
				}
			})
	}

	const firedAction = ({ text, param }) => {
		switch (text) {
			case 'Seanslar':
				navigate(`${param}/sessions`)
				break

			case 'Katılımcılar':
				navigate(`${param}/participants`)
				break

			case 'Düzenle':
				navigate(`edit/${param}`)
				break
			case 'Sil':
				removeWorkshop(param)
				break
			default:
				break
		}
	}

	useEffect(() => {
		fetchWorkshops()
	}, [])

	return (
		<PageLayout title="Atölyeler" createSlot={<CreateButton text="Yeni Atölye" link="create" />}>
			<Card>
				<Table columns={columns} data={workshops} pending={pending} actions={actions} firedAction={firedAction} />
			</Card>
		</PageLayout>
	)
}
