import { useNavigate } from 'react-router-dom'

import Button from '../shared/Button'

function CancelButton({ onClick, ...props }) {
	const navigate = useNavigate()

	const handleClick = () => {
		if (onClick) {
			onClick()
			return
		}

		navigate(-1)
	}

	return (
		<Button variant="secondary" onClick={handleClick} icon="bi bi-x-circle" styleName="me-2" {...props}>
			Vazgeç
		</Button>
	)
}

export default CancelButton
