import moment from './moment'

export default function dateRangeFormater(dateRange, splitter = ' - ') {
	const dates = dateRange.split(splitter)

	const startDate = moment(dates[0], 'YYYY-MM-DD').format('DD.MM.YYYY')
	const endDate = moment(dates[1], 'YYYY-MM-DD').format('DD.MM.YYYY')

	return `${startDate} - ${endDate}`
}
