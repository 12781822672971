import PropTypes from 'prop-types'

function CardLoader({ height, loaderSize }) {
	return (
		<div className="d-flex align-items-center justify-content-center" style={{ height }}>
			<div className="spinner-grow" style={{ width: loaderSize, height: loaderSize }}>
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	)
}

CardLoader.propTypes = {
	height: PropTypes.number,
	loaderSize: PropTypes.number
}

CardLoader.defaultProps = {
	height: 200,
	loaderSize: 48
}

export default CardLoader
