import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'
import { currency } from '../../utils/helpers'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CreateButton from '../../components/buttons/CreateButton'
import Card from '../../components/shared/Card'
import Table from '../../components/shared/Table'
import SortableTable from './SortableTable'
import Button from '../../components/shared/Button'
import SaveButton from '../../components/buttons/SaveButton'
import CancelButton from '../../components/buttons/CancelButton'

const columns = [
	{
		name: 'Eser',
		selector: (row) => row.name,
		sortable: true
	},
	{
		name: 'Sanatçı',
		selector: (row) => row.artistId.name,
		sortable: true
	},
	{
		name: 'Stok Kodu',
		selector: (row) => row.stockCode,
		sortable: true,
		width: '15%'
	},
	{
		name: 'Stok',
		selector: (row) => row.stock,
		sortable: true,
		width: '10%'
	},
	{
		name: 'Fiyat',
		selector: (row) => currency(row.price),
		sortable: true,
		width: '15%'
	},
	{
		name: 'Durum',
		cell: (row) =>
			row.status ? (
				<span className="badge bg-light-success">Aktif</span>
			) : (
				<span className="badge bg-light-secondary">Pasif</span>
			),
		button: true
	}
]

export function Works() {
	const navigate = useNavigate()

	const [works, setWorks] = useState([])
	const [sortableWorks, setSortableWorks] = useState([])
	const [pending, setPending] = useState(true)
	const [sortableMode, setSortableMode] = useState(false)

	const mapSortableWorks = (works) => {
		const sortableWorks = works.map((work) => ({
			id: work._id,
			name: work.name,
			stockCode: work.stockCode,
			artist: work.artistId.name,
			displayOrder: work.order
		}))

		setSortableWorks(sortableWorks)
	}

	const fetchWorks = () => {
		http
			.get('/works')
			.then(({ data }) => {
				setWorks(data)
				mapSortableWorks(data)
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setPending(false)
				setSortableMode(false)
				setSaveSortLoading(false)
			})
	}

	const removeWork = (id) => {
		http
			.delete(`/works/${id}`)
			.then(() => {
				toast.success('Eser silindi!')
				fetchWorks()
			})
			.catch(({ response }) => {
				if (response.data?.showUser) {
					toast.error(response.data?.message)
				}
			})
	}

	const firedAction = ({ text, param }) => {
		switch (text) {
			case 'Düzenle':
				navigate(`edit/${param}`)
				break
			case 'Sil':
				removeWork(param)
				break
			default:
				break
		}
	}

	const cancelSort = () => {
		mapSortableWorks(works)
		setSortableMode(false)
	}

	const [saveSortLoading, setSaveSortLoading] = useState(false)

	const saveSort = () => {
		setSaveSortLoading(true)
		const idList = sortableWorks
			.map((work, index) => ({
				id: work.id,
				oldOrder: work.displayOrder,
				newOrder: index
			}))
			.filter((work) => work.oldOrder !== work.newOrder)
			.map((work) => ({
				id: work.id,
				newOrder: work.newOrder
			}))

		http
			.post('/works/change-sort', { idList })
			.then(() => fetchWorks())
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		fetchWorks()
	}, [])

	return (
		<PageLayout title="Eserler" createSlot={<CreateButton text="Yeni Eser" link="create" />}>
			<Card>
				{!pending && (
					<div className="text-end mb-1">
						{!sortableMode ? (
							<Button icon="bi bi-arrows-expand" variant="warning" onClick={() => setSortableMode(true)}>
								Sıralamayı Değiştir
							</Button>
						) : (
							<>
								<CancelButton disabled={saveSortLoading} onClick={cancelSort} />
								<SaveButton type="button" loading={saveSortLoading} onClick={saveSort} />
							</>
						)}
					</div>
				)}
				{!sortableMode ? (
					<Table columns={columns} data={works} pending={pending} firedAction={firedAction} />
				) : (
					<SortableTable works={sortableWorks} onChangeSort={setSortableWorks} />
				)}
			</Card>
		</PageLayout>
	)
}
