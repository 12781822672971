import PropTypes from 'prop-types'

function Card({ children, title }) {
	return (
		<div className="card">
			{!!title && (
				<div className="card-header">
					<h4>{title}</h4>
				</div>
			)}
			<div className="card-body">{children}</div>
		</div>
	)
}

Card.propTypes = {
	title: PropTypes.string
}

Card.defaultProps = {
	title: ''
}

export default Card
