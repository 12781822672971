import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'
import classNames from 'classnames'

function Checkbox({ label, size, ...props }) {
	const [field, meta, helpers] = useField(props.name)
	const styles = classNames('form-check', { [`form-check-${size}`]: !!size })

	return (
		<div className={styles}>
			<div className="checkbox">
				<input type="checkbox" className="form-check-input" {...field} {...props} />
				<label htmlFor={props.id}>{label}</label>
			</div>
		</div>
	)
}

Checkbox.propTypes = {
	label: PropTypes.string,
	size: PropTypes.string
}

Checkbox.defaultProps = {
	label: '',
	size: ''
}

export default Checkbox
