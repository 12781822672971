import PropTypes from 'prop-types'
import classNames from 'classnames'

function FormGroup({ children, label, labelFor, topText, bottomText, styleName, icon, iconPosition, ...props }) {
	const styles = classNames('form-group', styleName, {
		'position-relative': !!icon,
		[`has-icon-${iconPosition}`]: !!icon
	})

	return (
		<div className={styles} {...props}>
			{!!label && (
				<>
					<label htmlFor={labelFor} className="mb-1">
						{label}
					</label>{' '}
					{!!topText && <small className="text-muted">{topText}</small>}
				</>
			)}
			{children}
			{!!icon && (
				<div className="form-control-icon">
					<i className={icon}></i>
				</div>
			)}
			{!!bottomText && (
				<p>
					<small className="text-muted">{bottomText}</small>
				</p>
			)}
		</div>
	)
}

FormGroup.propTypes = {
	label: PropTypes.string,
	labelFor: PropTypes.string,
	topText: PropTypes.string,
	bottomText: PropTypes.string,
	styleName: PropTypes.string,
	icon: PropTypes.string,
	iconPosition: PropTypes.string
}

FormGroup.defaultProps = {
	label: '',
	labelFor: '',
	topText: '',
	bottomText: '',
	styleName: '',
	icon: '',
	iconPosition: 'left'
}

export default FormGroup
