import Yup from './config'

export const WorkSchema = Yup.object().shape({
	artistId: Yup.object().required(),
	stockCode: Yup.string().required(),
	stock: Yup.string().required(),
	price: Yup.number().required(),
	discountedPrice: Yup.number().default(0),
	shippingPrice: Yup.number().default(0),
	hasVat: Yup.boolean().required(),
	vatRate: Yup.number().when('hasVat', {
		is: true,
		then: Yup.number().required()
	}),
	name: Yup.string().required(),
	description: Yup.string().required(),
	gallery: Yup.array()
		.of(
			Yup.object().shape({
				image: Yup.string().required()
			})
		)
		.required(),
	filters: Yup.array().of(
		Yup.object().shape({
			value: Yup.string().required(),
			label: Yup.string().required()
		})
	),
	nameEn: Yup.string(),
	descriptionEn: Yup.string()
})
