import { useEffect, useState } from 'react'
import { useParams, useNavigate, NavLink } from 'react-router-dom'

import http from '../../utils/config/service'
import { currency, moment } from '../../utils/helpers/index'

import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/shared/Card'
import Tab from '../../components/shared/Tab'
import CardLoader from '../../components/shared/CardLoader'
import ReactJson from 'react-json-view'
import { paymentStatusValues, paymentType, statusValues } from '../../utils/config/constants'
import FormGroup from '../../components/shared/FormGroup'
import SaveButton from '../../components/buttons/SaveButton'
import CancelButton from '../../components/buttons/CancelButton'
import { toast } from 'react-toastify'

export function OrderDetail() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [cardLoading, setCardLoading] = useState(true)
	const [order, setOrder] = useState(null)
	const [paymentStatus, setPaymentStatus] = useState('waiting')
	const [status, setStatus] = useState('waiting')
	const [shippingTrackingLink, setShippingTrackingLink] = useState('')
	const [reason, setReason] = useState('')
	const [saveLoading, setSaveLoading] = useState(false)

	const setData = (data) => {
		setOrder(data)
		setPaymentStatus(data.paymentStatus)
		setStatus(data.status)
		setShippingTrackingLink(data.shippingTrackingLink)
		setReason(data.reason)
	}

	useEffect(() => {
		http
			.get(`/orders/${id}`)
			.then(({ data }) => setData(data))
			.catch(() => navigate('/orders'))
			.finally(() => setCardLoading(false))
	}, [])

	const handleSubmit = (e) => {
		e.preventDefault()
		setSaveLoading(false)
		const payload = {
			paymentStatus,
			status,
			reason,
			shippingTrackingLink
		}

		http
			.patch(`/orders/${id}`, payload)
			.then(({ data }) => {
				toast.success('Değişikliker kaydedildi!')
				setData(data)
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Sipariş Detayı">
			{cardLoading && (
				<Card>
					<CardLoader />
				</Card>
			)}
			{!cardLoading && (
				<div className="conainer">
					<div className="row">
						<div className="col-md-8">
							<Card>
								<Tab>
									<Tab.Pane title="Alıcı Bilgileri">
										<table className="table">
											<tbody>
												<tr>
													<th width="20%">Tip</th>
													<td>{order.buyerType === 'guest' ? 'Misafir' : 'Müşteri'}</td>
												</tr>
												<tr>
													<th width="20%">Adı Soyadı</th>
													<td>
														{order.buyer.name} {order.buyer.surname}
													</td>
												</tr>
												<tr>
													<th width="20%">E-Posta</th>
													<td>
														<a href={`mailto:${order.buyer.email}`}>{order.buyer.email}</a>
													</td>
												</tr>
												<tr>
													<th width="20%">Telefon</th>
													<td>
														<a href={`tel:${order.buyer.phone}`}>{order.buyer.phone}</a>
													</td>
												</tr>
												<tr>
													<th width="20%">Kimlik No</th>
													<td>{order.buyer.identityNumber}</td>
												</tr>
												{order.buyerType === 'customer' && (
													<>
														<tr>
															<th width="20%">Durum</th>
															<td>
																{order.customer.isActive && <span className="badge bg-light-success">Aktif</span>}
																{!order.customer.isActive && <span className="badge bg-light-secondary">Pasif</span>}
															</td>
														</tr>
														<tr>
															<th width="20%">Onaylı mı?</th>
															<td>
																{order.customer.isApproved && <span className="badge bg-light-success">Evet</span>}
																{!order.customer.isApproved && <span className="badge bg-light-secondary">Hayır</span>}
															</td>
														</tr>
														<tr>
															<th width="20%">İletişim İzni</th>
															<td>
																{order.customer.contactPermission && (
																	<span className="badge bg-light-success">Var</span>
																)}
																{!order.customer.contactPermission && (
																	<span className="badge bg-light-secondary">Yok</span>
																)}
															</td>
														</tr>
														<tr>
															<th width="20%">Kayıt Tarihi</th>
															<td>{moment(order.customer.createdAt).format('DD.MM.YYYY hh:mm')}</td>
														</tr>
													</>
												)}
											</tbody>
										</table>
									</Tab.Pane>
									<Tab.Pane title="Teslimat Bilgileri">
										{order.deliveryType === 'gallery' && 'Galeriden Teslimat'}
										{order.deliveryType === 'address' && (
											<div className="row">
												<div className="col-md-6">
													<h6>Teslimat Adresi</h6>
													<div>{order.shippingAddress.fullName}</div>
													<small>{order.shippingAddress.phone}</small>
													<p>
														{order.shippingAddress.address}
														<br />
														{order.shippingAddress.district} / {order.shippingAddress.city}
													</p>
												</div>
												<div className="col-md-6">
													<h6>Fatura Adresi</h6>
													<div>{order.billingAddress.fullName}</div>
													<small>{order.billingAddress.phone}</small>
													<p>
														{order.billingAddress.address}
														<br />
														{order.billingAddress.district} / {order.billingAddress.city}
													</p>
												</div>
											</div>
										)}
									</Tab.Pane>
									<Tab.Pane title="Sepet">
										{order.cartDetails.products.map((product) => (
											<div key={product.id}>
												<table className="table">
													<thead>
														<tr>
															<th colSpan="2">Ürün</th>
															<th>Birim Fiyatları</th>
															<th>Miktar</th>
															<th>Toplam</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td width="50">
																<NavLink to={`/works/edit/${product.id}`}>
																	<img src={product.picture} alt={product.name} className="img-fluid" />
																</NavLink>
															</td>
															<td>
																<div>{product.name}</div>
																<small>{product.artist}</small>
															</td>
															<td>
																<table style={{ fontSize: 13 }}>
																	<tr>
																		<td>
																			<strong>Fiyat:</strong> {currency(product.price)}
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<strong>İndirimli Fiyat:</strong> {currency(product.discountedPrice)}
																		</td>
																	</tr>
																</table>
															</td>
															<td>{product.quantity}</td>
															<td>
																<table style={{ fontSize: 13 }}>
																	<tr>
																		<td>
																			<strong>Fiyat:</strong> {currency(product.totalPrice)}
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<strong>İndirimli Fiyat:</strong> {currency(product.totalDiscountedPrice)}
																		</td>
																	</tr>
																</table>
															</td>
														</tr>
														<tr>
															<td colSpan="2">
																<strong>Kargo</strong>
															</td>
															<td>{currency(product.shippingPrice)}</td>
															<td>{product.quantity}</td>
															<td>{currency(product.totalShippingPrice)}</td>
														</tr>
														{product.hasVat && (
															<tr>
																<td colSpan="2">
																	<strong>Vergiler</strong>
																</td>
																<td colSpan="2">
																	<table style={{ fontSize: 13 }}>
																		<tr>
																			<td>
																				<strong>Oran:</strong> %{product.vatRate}
																			</td>
																		</tr>
																	</table>
																</td>
																<td>
																	<table style={{ fontSize: 13 }}>
																		<tr>
																			<td>
																				<strong>Fiyat Üzerinden:</strong> {currency(product.totalPriceVat)}
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<strong>İndirimli Fiyat Üzerinden:</strong>{' '}
																				{currency(product.totalDiscountedVat)}
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														)}
														<tr>
															<td colSpan="4">
																<strong>Toplam Tutar</strong>
															</td>
															<td>{currency(product.totalPayableAmount)}</td>
														</tr>
													</tbody>
												</table>
												<hr />
											</div>
										))}
										<hr />
										<table className="table mt-4" style={{ textAlign: 'right' }}>
											<tbody>
												<tr>
													<th>Toplam Kargo</th>
													<td>{currency(order.cartDetails.totalShippingAmount)}</td>
												</tr>
												{order.cartDetails.totalDiscountedAmount > 0 && (
													<>
														<tr>
															<th>Toplam İndirimli Fiyat</th>
															<td>{currency(order.cartDetails.totalDiscountedAmount)}</td>
														</tr>
														<tr>
															<th>Toplam İndirimki Fiyat + KDV</th>
															<td>{currency(order.cartDetails.totalDiscountedAmountWithVat)}</td>
														</tr>
													</>
												)}
												<tr>
													<th>Toplam Fiyat</th>
													<td>{currency(order.cartDetails.totalAmount)}</td>
												</tr>
												<tr>
													<th>Toplam Fiyat + KDV</th>
													<td>{currency(order.cartDetails.totalAmountWithVat)}</td>
												</tr>
												<tr>
													<th>Toplam KDV</th>
													<td>{currency(order.cartDetails.totalVatAmount)}</td>
												</tr>
												<tr>
													<th>Toplam Tutar</th>
													<td>{currency(order.cartDetails.totalPayableAmount)}</td>
												</tr>
											</tbody>
										</table>
									</Tab.Pane>
									<Tab.Pane title="İyzico Verileri">
										<ReactJson src={order.paymentDetails} />
									</Tab.Pane>
								</Tab>
							</Card>
						</div>
						<div className="col-md-4">
							<Card>
								<div>
									<strong>Ödeme Tipi:</strong> {paymentType[order.paymentType]}
								</div>
								<div className="mb-3">
									<strong>Topam Tutar:</strong> {currency(order.cartDetails.totalPayableAmount)}
								</div>
								<form onSubmit={handleSubmit}>
									<FormGroup label="Ödeme Durumu" labelFor="paymentStatus">
										<select
											className="form-select"
											id="paymentStatus"
											value={paymentStatus}
											onChange={({ target }) => setPaymentStatus(target.value)}>
											{paymentStatusValues.map((status, index) => (
												<option key={index} value={status.value}>
													{status.label}
												</option>
											))}
										</select>
									</FormGroup>
									<FormGroup label="Sipariş Durumu" labelFor="status">
										<select
											className="form-select"
											id="status"
											value={status}
											onChange={({ target }) => setStatus(target.value)}>
											{statusValues.map((status, index) => (
												<option key={index} value={status.value}>
													{status.label}
												</option>
											))}
										</select>
									</FormGroup>
									{status === 'shipped' && (
										<FormGroup label="Kargo Takip Linki" labelFor="shippingTrackingLink">
											<input
												type="text"
												name="shippingTrackingLink"
												id="shippingTrackingLink"
												className="form-control"
												value={shippingTrackingLink}
												onChange={({ target }) => setShippingTrackingLink(target.value)}
											/>
										</FormGroup>
									)}
									{(status === 'returned' || status === 'canceled') && (
										<FormGroup label="Sebep" labelFor="reason">
											<input
												type="text"
												name="reason"
												id="reason"
												className="form-control"
												value={reason}
												onChange={({ target }) => setReason(target.value)}
											/>
										</FormGroup>
									)}
									<hr />
									<div className="text-end">
										<CancelButton link="/orders" />
										<SaveButton loading={saveLoading} />
									</div>
								</form>
							</Card>
						</div>
					</div>
				</div>
			)}
		</PageLayout>
	)
}
