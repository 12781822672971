import { useEffect, useState } from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'
import { moment } from '../../utils/helpers'

import { toast } from 'react-toastify'
import { paymentStatus } from '../../utils/config/constants'

import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/shared/Card'
import Table from '../../components/shared/Table'
import Button from '../../components/shared/Button'

const columns = [
	{
		name: 'İsim',
		selector: (row) => `${row.name} ${row.surname}`,
		sortable: true
	},
	{
		name: 'E-Posta',
		cell: (row) => <a href={`mailto:${row.email}`}>{row.email}</a>,
		selector: (row) => row.email,
		sortable: true
	},
	{
		name: 'Telefon',
		cell: (row) => <a href={`tel:${row.phone}`}>{row.phone}</a>,
		selector: (row) => row.phone,
		sortable: true
	},
	{
		name: 'Seans',
		cell: (row) => (
			<span>
				{moment(row.session.date).format('DD.MM.Y')}
				<br />
				{row.session.time}
			</span>
		),
		selector: (row) => `${row.session.date}-${row.session.time}`,
		sortable: true
	},
	{
		name: 'Ödeme',
		cell: (row) => (
			<span className={`badge bg-light-${paymentStatus[row.paymentStatus].className}`}>
				{paymentStatus[row.paymentStatus].text}
			</span>
		),
		selector: (row) => paymentStatus[row.paymentStatus].text,
		sortable: true,
		button: true
	}
]

function BackButton() {
	const navigate = useNavigate()

	return (
		<Button variant="secondary" icon="bi bi-arrow-left" onClick={() => navigate(-1)}>
			Geri
		</Button>
	)
}

export default function Participants() {
	const { workshopId } = useParams()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const [pending, setPending] = useState(true)
	const [workshop, setWorkshop] = useState([])
	const [participants, setParticipants] = useState([])

	const fetchWorkshop = async () => {
		await http
			.get('/ateliers/' + workshopId)
			.then(({ data }) => setWorkshop(data))
			.catch((response) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
	}

	const fetchParticipants = () => {
		let query = `ateleiers=${workshopId}`

		if (searchParams.get('session')) {
			query += `&session=${searchParams.get('session')}`
		}

		http
			.get(`/ateliers/participants?${query}`)
			.then(({ data }) => {
				setParticipants(data)
			})
			.catch((err) => console.log(err))
			.finally(() => setPending(false))
	}

	const removeParticipant = (participantId) => {
		http
			.delete(`/ateliers/participant/${participantId}`)
			.then(() => {
				toast.success('Katılımcı silindi!')
				fetchParticipants()
			})
			.catch(({ response }) => {
				if (response.data?.showUser) {
					toast.error(response.data?.message)
				}
			})
	}

	const firedAction = ({ text, param }) => {
		switch (text) {
			case 'Detaylar':
				navigate(param)
				break

			case 'Sil':
				removeParticipant(param)
				break

			default:
				break
		}
	}

	useEffect(() => {
		fetchWorkshop()
		fetchParticipants()
	}, [])

	return (
		<PageLayout title={!pending ? workshop.title : '...'} subtitle="Katılımcılar" createSlot={<BackButton />}>
			<Card>
				<Table
					columns={columns}
					data={participants || []}
					pending={pending}
					editable={false}
					actions={[{ icon: 'bi bi-search', text: 'Detaylar' }]}
					firedAction={firedAction}
				/>
			</Card>
		</PageLayout>
	)
}
