import { useState } from 'react'
import PropTypes from 'prop-types'

function Tab({ children, activeTab, tabNavSpace }) {
	const [currentTab, setCurrentTab] = useState(activeTab)

	return (
		<div className="tab">
			<ul className={`nav nav-tabs mb-${tabNavSpace}`}>
				{children.map((tab, index) => (
					<li key={index} className="nav-item">
						<button
							type="button"
							className={`nav-link ${currentTab == index ? 'active' : ''}`}
							style={{ fontSize: 14 }}
							onClick={() => setCurrentTab(index)}>
							{tab.props.title}
						</button>
					</li>
				))}
			</ul>
			<div className="tab-content">
				{children.map((tab, index) => (
					<div key={index} className={`tab-pane ${currentTab == index ? 'active' : ''}`}>
						{tab.props.children}
					</div>
				))}
			</div>
		</div>
	)
}

Tab.propTypes = {
	activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	tabNavSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Tab.defaultProps = {
	activeTab: 0,
	tabNavSpace: 3
}

Tab.Pane = ({ children, title }) => {
	return <>{children}</>
}

Tab.Pane.propTypes = {
	title: PropTypes.string.isRequired
}

export default Tab
