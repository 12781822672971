import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CreateButton from '../../components/buttons/CreateButton'
import Card from '../../components/shared/Card'
import Table from '../../components/shared/Table'

const columns = [
	{
		name: 'İsim',
		selector: (row) => row.name,
		sortable: true
	}
]

export function Artists() {
	const navigate = useNavigate()

	const [artists, setArtists] = useState([])
	const [pending, setPending] = useState(true)

	const fetchArtists = () => {
		http
			.get('/artists')
			.then(({ data }) => setArtists(data))
			.catch((err) => console.log(err))
			.finally(() => setPending(false))
	}

	const removeArtist = (id) => {
		http
			.delete(`/artists/${id}`)
			.then(() => {
				toast.success('Sanatçı silindi!')
				fetchArtists()
			})
			.catch(({ response }) => {
				if (response.data?.showUser) {
					toast.error(response.data?.message)
				}
			})
	}

	const firedAction = ({ text, param }) => {
		switch (text) {
			case 'Düzenle':
				navigate(`edit/${param}`)
				break
			case 'Sil':
				removeArtist(param)
				break
			default:
				break
		}
	}

	useEffect(() => {
		fetchArtists()
	}, [])

	return (
		<PageLayout title="Sanatçılar" createSlot={<CreateButton text="Yeni Sanatçı" link="create" />}>
			<Card>
				<Table columns={columns} data={artists} pending={pending} firedAction={firedAction} />
			</Card>
		</PageLayout>
	)
}
