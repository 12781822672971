import { Link } from 'react-router-dom'

import './style.css'

function NotFound() {
	return (
		<div id="error">
			<div className="error-page container">
				<div className="col-md-8 col-12 offset-md-2">
					<div className="text-center">
						<img className="img-error" src="/assets/images/samples/error-404.svg" alt="Not Found" />
						<h1 className="error-title">SAYFA BULUNAMADI</h1>
						<p className="fs-5 text-gray-600">Aradığınız sayfa bulunamadı.</p>
						<Link to="/" className="btn btn-lg btn-outline-primary mt-3">
							Ana Sayfa'ya Git
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotFound
