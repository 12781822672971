import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/shared/Card'
import Table from '../../components/shared/Table'

const columns = [
	{
		name: 'İsim',
		selector: (row) => `${row.name} ${row.surname}`,
		sortable: true
	},
	{
		name: 'E-Posta',
		selector: (row) => row.email,
		sortable: true
	},
	{
		name: 'Durum',
		cell: (row) =>
			row.isActive ? (
				<span className="badge bg-light-success">Aktif</span>
			) : (
				<span className="badge bg-light-secondary">Pasif</span>
			),
		button: true
	},
	{
		name: 'Onaylı Mı?',
		cell: (row) =>
			row.isApproved ? (
				<span className="badge bg-light-success">Evet</span>
			) : (
				<span className="badge bg-light-secondary">Hayır</span>
			),
		button: true
	},
	{
		name: 'İletişim İzni',
		cell: (row) =>
			row.contactPermission ? (
				<span className="badge bg-light-success">Var</span>
			) : (
				<span className="badge bg-light-secondary">Yok</span>
			),
		button: true
	}
]

export function Customers() {
	const navigate = useNavigate()
	const [customers, setCustomers] = useState([])
	const [pending, setPending] = useState(true)

	const fetchCustomers = () => {
		http
			.get('/customers')
			.then(({ data }) => setCustomers(data))
			.catch((err) => console.log(err))
			.finally(() => setPending(false))
	}

	const firedAction = ({ text, param }) => {
		switch (text) {
			case 'Düzenle':
				navigate(`/customers/edit/${param}`)
				break

			default:
				break
		}
	}

	useEffect(() => {
		fetchCustomers()
	}, [])

	return (
		<PageLayout title="Müşteriler">
			<Card>
				<Table removable={false} columns={columns} data={customers} pending={pending} firedAction={firedAction} />
			</Card>
		</PageLayout>
	)
}
