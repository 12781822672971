import PropTypes from 'prop-types'

import { WorkshopSchema } from '../../utils/validations'

import { Formik, Form } from 'formik'

import Card from '../../components/shared/Card'
import Tab from '../../components/shared/Tab'
import FormGroup from '../../components/shared/FormGroup'
import Textbox from '../../components/shared/Textbox'
import RichText from '../../components/rich-text'
import FilePicker from '../../components/file-picker'
import Checkbox from '../../components/shared/Checkbox'
import CancelButton from '../../components/buttons/CancelButton'
import SaveButton from '../../components/buttons/SaveButton'

function WorkshopForm({ initialValues, loading, onSubmit }) {
	const handleSubmit = (values) => {
		const payload = {
			title: values.title,
			description: values.description,
			image: values.image,
			instructor: values.instructor,
			translations: {
				en: {
					title: values.titleEn,
					description: values.descriptionEn
				}
			},
			price: values.price,
			isCurrent: values.isCurrent,
			order: values.order
		}

		onSubmit(payload)
	}

	return (
		<Formik enableReinitialize initialValues={initialValues} validationSchema={WorkshopSchema} onSubmit={handleSubmit}>
			{({ values, handleChange, handleBlur, errors, touched }) => (
				<Form>
					<div className="row">
						<div className="col-md-8">
							<Card>
								<Tab>
									<Tab.Pane title="Türkçe">
										<FormGroup label="Başlık" labelFor="title">
											<Textbox
												type="text"
												name="title"
												placeholder="Atölye Başlığı"
												value={values.title}
												id="title"
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</FormGroup>
										<FormGroup label="Açıklama">
											<RichText name="description" placeholder="Atölye Açıklaması" errors={errors} touched={touched} />
										</FormGroup>
									</Tab.Pane>
									<Tab.Pane title="İngilizce">
										<FormGroup label="Başlık" labelFor="titleEn">
											<Textbox
												type="text"
												name="titleEn"
												placeholder="Workshop Title"
												value={values.titleEn}
												id="titleEn"
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</FormGroup>
										<FormGroup label="Açıklama">
											<RichText
												name="descriptionEn"
												placeholder="Workshop Description"
												errors={errors}
												touched={touched}
											/>
										</FormGroup>
									</Tab.Pane>
								</Tab>
							</Card>
						</div>
						<div className="col-md-4">
							<Card>
								<FormGroup label="Eğitmen" labelFor="instructor">
									<Textbox
										type="text"
										name="instructor"
										value={values.instructor}
										id="instructor"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Fotoğraf">
									<FilePicker name="image" value={values.image} />
								</FormGroup>
								<FormGroup label="Fiyat" labelFor="price">
									<Textbox
										type="number"
										name="price"
										id="price"
										placeholder="Ör: 10000"
										value={values.price}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Gösterim Sırası" labelFor="order">
									<Textbox
										type="number"
										name="order"
										value={values.order}
										id="order"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Geçerli Mi?">
									<Checkbox
										label="Evet / Hayır"
										name="isCurrent"
										id="isCurrent"
										checked={values.isCurrent}
										onChange={handleChange}
									/>
								</FormGroup>
								<hr />
								<div className="text-end">
									<CancelButton link="/workshops" />
									<SaveButton loading={loading} />
								</div>
							</Card>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

WorkshopForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default WorkshopForm
