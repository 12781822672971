import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import FieldForm from '../../components/forms/FieldForm'

export default function PageEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [requestLoader, setRequestLoader] = useState(true)
	const [initialValues, setInitialValues] = useState({
		title: '',
		key: '',
		items: [],
		refType: 'work'
	})
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/fields/${id}`)
			.then(({ data }) => {
				let items = data.items.map((item) => ({ value: item._id, label: `${item.name} - ${item.stockCode}` }))

				if (data.refType === 'artist') {
					items = data.items.map((item) => ({ value: item._id, label: item.name }))
				}

				setInitialValues({
					title: data.title,
					key: data.key,
					items,
					refType: data.refType
				})
				setRequestLoader(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.patch(`/fields/${id}`, payload)
			.then(() => {
				toast.success('Alan düzenlendi!')
				navigate('/fields')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Alanı Düzenle">
			{requestLoader && <CardLoader />}
			{!requestLoader && <FieldForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />}
		</PageLayout>
	)
}
