import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import http from '../../utils/config/service'

import PageLayout from '../../layouts/PageLayout'
import CreateButton from '../../components/buttons/CreateButton'
import Card from '../../components/shared/Card'
import Table from '../../components/shared/Table'

const columns = [
	{
		name: 'İsim',
		selector: (row) => row.fullName,
		sortable: true
	},
	{
		name: 'E-Posta',
		selector: (row) => row.email,
		sortable: true
	},
	{
		name: 'Durum',
		cell: (row) =>
			row.isActive ? (
				<span className="badge bg-light-success">Aktif</span>
			) : (
				<span className="badge bg-light-secondary">Pasif</span>
			),
		button: true
	}
]

export function Users() {
	const navigate = useNavigate()
	const [users, setUsers] = useState([])
	const [pending, setPending] = useState(true)

	const fetchUsers = () => {
		http
			.get('/users')
			.then(({ data }) => setUsers(data))
			.catch((err) => console.log(err))
			.finally(() => setPending(false))
	}

	const firedAction = ({ text, param }) => {
		switch (text) {
			case 'Düzenle':
				navigate(`/users/edit/${param}`)
				break

			case 'Sil':
				removeUser(param)
				break

			default:
				break
		}
	}

	const removeUser = (id) => {
		http
			.delete(`/users/${id}`)
			.then(() => {
				toast.success('Kullanıcı silindi!')
				fetchUsers()
			})
			.catch(({ response }) => {
				if (response.data?.showUser) {
					toast.error(response.data?.message)
				}
			})
	}

	useEffect(() => {
		fetchUsers()
	}, [])

	return (
		<PageLayout title="Yöneticiler" createSlot={<CreateButton text="Yeni Yönetici" link="create" />}>
			<Card>
				<Table columns={columns} data={users} pending={pending} firedAction={firedAction} />
			</Card>
		</PageLayout>
	)
}
