import Login from './pages/login'
import DefaultLayout from './layouts/DefaultLayout'
import Home from './pages/home'
import { Artists, ArtistCreate, ArtistEdit } from './pages/artist'
import { Works, WorkCreate, WorkEdit } from './pages/works'
import { Exhibitions, ExhibitionCreate, ExhibitionEdit } from './pages/exhibition'
import {
	Workshops,
	WorkshopCreate,
	WorkshopEdit,
	WorkshopSessions,
	WorkshopSessionCreate,
	WorkshopSessionEdit,
	Participants,
	ParticipantDetail
} from './pages/workshop'
import {
	FilterGroups,
	FilterGroupCreate,
	FilterGroupEdit,
	FilterItems,
	FilterItemCreate,
	FilterItemEdit
} from './pages/filter'
import { Customers, CustomerEdit } from './pages/customer'
import { Orders, OrderDetail } from './pages/order'
import { Templates, TemplateCreate, TemplateEdit } from './pages/template'
import { Pages, PageCreate, PageEdit } from './pages/page'
import Media from './pages/media'
import { Fields, FieldCreate, FieldEdit } from './pages/field'
import { Users, UserCreate, UserEdit } from './pages/user'
import NotFound from './pages/not-found'

const routes = [
	{
		path: '/login',
		element: <Login />
	},
	{
		path: '/',
		element: <DefaultLayout />,
		children: [
			{
				index: true,
				element: <Home />
			},
			{
				path: 'artists',
				children: [
					{
						index: true,
						element: <Artists />
					},
					{
						path: 'create',
						element: <ArtistCreate />
					},
					{
						path: 'edit/:id',
						element: <ArtistEdit />
					}
				]
			},
			{
				path: 'works',
				children: [
					{
						index: true,
						element: <Works />
					},
					{
						path: 'create',
						element: <WorkCreate />
					},
					{
						path: 'edit/:id',
						element: <WorkEdit />
					}
				]
			},
			{
				path: 'exhibitions',
				children: [
					{
						index: true,
						element: <Exhibitions />
					},
					{
						path: 'create',
						element: <ExhibitionCreate />
					},
					{
						path: 'edit/:id',
						element: <ExhibitionEdit />
					}
				]
			},
			{
				path: 'workshops',
				children: [
					{
						index: true,
						element: <Workshops />
					},
					{
						path: 'create',
						element: <WorkshopCreate />
					},
					{
						path: 'edit/:id',
						element: <WorkshopEdit />
					},
					{
						path: ':workshopId/sessions',
						element: <WorkshopSessions />
					},
					{
						path: ':workshopId/sessions/create',
						element: <WorkshopSessionCreate />
					},
					{
						path: ':workshopId/sessions/edit/:id',
						element: <WorkshopSessionEdit />
					},
					{
						path: ':workshopId/participants',
						element: <Participants />
					},
					{
						path: ':workshopId/participants/:id',
						element: <ParticipantDetail />
					}
				]
			},
			{
				path: 'filter-groups',
				children: [
					{
						index: true,
						element: <FilterGroups />
					},
					{
						path: 'create',
						element: <FilterGroupCreate />
					},
					{
						path: 'edit/:id',
						element: <FilterGroupEdit />
					}
				]
			},
			{
				path: 'filters',
				children: [
					{
						index: true,
						element: <FilterItems />
					},
					{
						path: 'create',
						element: <FilterItemCreate />
					},
					{
						path: 'edit/:id',
						element: <FilterItemEdit />
					}
				]
			},
			{
				path: 'customers',
				children: [
					{
						index: true,
						element: <Customers />
					},
					{
						path: 'edit/:id',
						element: <CustomerEdit />
					}
				]
			},
			{
				path: 'orders',
				children: [
					{
						index: true,
						element: <Orders />
					},
					{
						path: 'detail/:id',
						element: <OrderDetail />
					}
				]
			},
			{
				path: 'templates',
				children: [
					{
						index: true,
						element: <Templates />
					},
					{
						path: 'create',
						element: <TemplateCreate />
					},
					{
						path: 'edit/:id',
						element: <TemplateEdit />
					}
				]
			},
			{
				path: 'pages',
				children: [
					{
						index: true,
						element: <Pages />
					},
					{
						path: 'create',
						element: <PageCreate />
					},
					{
						path: 'edit/:id',
						element: <PageEdit />
					}
				]
			},
			{
				path: 'media',
				element: <Media />
			},
			{
				path: 'fields',
				children: [
					{
						index: true,
						element: <Fields />
					},
					{
						path: 'create',
						element: <FieldCreate />
					},
					{
						path: 'edit/:id',
						element: <FieldEdit />
					}
				]
			},
			{
				path: 'users',
				children: [
					{
						index: true,
						element: <Users />
					},
					{
						path: 'create',
						element: <UserCreate />
					},
					{
						path: 'edit/:id',
						element: <UserEdit />
					}
				]
			}
		]
	},
	{
		path: '*',
		element: <NotFound />
	}
]

export default routes
