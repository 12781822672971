import Yup from './config'

export const ExhibitionSchema = Yup.object().shape({
	name: Yup.string().required().min(6),
	description: Yup.string().required(),
	works: Yup.array()
		.of(
			Yup.object().shape({
				value: Yup.string().required(),
				label: Yup.string().required()
			})
		)
		.required(),
	isCurrent: Yup.boolean(),
	order: Yup.number().min(0).required(),
	image: Yup.string().required(),
	startDate: Yup.string().required(),
	endDate: Yup.string().required(),
	nameEn: Yup.string(),
	descriptionEn: Yup.string()
})
