import Yup from './config'

export const FieldSchema = Yup.object().shape({
	title: Yup.string().required(),
	key: Yup.string().required(),
	items: Yup.array()
		.of(
			Yup.object().shape({
				value: Yup.string().required(),
				label: Yup.string().required()
			})
		)
		.required(),
	refTypes: Yup.mixed().oneOf(['work', 'artist'])
})
