import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import ExhibitionForm from '../../components/forms/ExhibitionForm'

export default function ExhibitionEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [requestLoader, setRequestLoader] = useState(true)
	const [initialValues, setInitialValues] = useState()
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/exhibitions/${id}`)
			.then(({ data }) => {
				const dates = data.date.split(' - ')
				setInitialValues({
					name: data.name,
					description: data.description,
					works: data.works.map((work) => ({ value: work._id, label: work.name })),
					isCurrent: data.isCurrent,
					order: data.order || 0,
					image: data.image,
					startDate: dates[0],
					endDate: dates[1],
					nameEn: data.translations.en.name,
					descriptionEn: data.translations.en.description
				})
				setRequestLoader(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.patch(`/exhibitions/${id}`, payload)
			.then(() => {
				toast.success('Sergi düzenlendi!')
				navigate('/exhibitions')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Sergiyi Düzenle">
			{requestLoader && <CardLoader />}
			{!requestLoader && <ExhibitionForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />}
		</PageLayout>
	)
}
