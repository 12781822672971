import Yup from './config'

export const WorkshopSchema = Yup.object().shape({
	title: Yup.string().required(),
	description: Yup.string().required(),
	instructor: Yup.string().required(),
	image: Yup.string().required(),
	titleEn: Yup.string(),
	descriptionEn: Yup.string(),
	price: Yup.number().required(),
	isCurrent: Yup.bool().required(),
	order: Yup.number().min(0).default(0)
})

export const SeansSchema = Yup.object().shape({
	date: Yup.string().required(),
	startTime: Yup.string().required(),
	endTime: Yup.string().required(),
	quota: Yup.number().typeError('Bu alan sadece rakamlardan oluşabilir!').required()
})

export const ParticipantSchema = Yup.object().shape({
	name: Yup.string().required(),
	surname: Yup.string().required(),
	email: Yup.string().email().required(),
	phone: Yup.string().required(),
	date: Yup.string().required(),
	message: Yup.string()
})
