import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import FilterItemForm from '../../components/forms/FilterItemForm'

export function FilterItemEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [requestLoader, setRequestLoader] = useState(true)
	const [initialValues, setInitialValues] = useState({
		name: '',
		nameEn: '',
		groupId: ''
	})
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/filters/items/${id}`)
			.then(({ data }) => {
				setInitialValues({
					name: data.name,
					nameEn: data.translations.en.name,
					groupId: { value: data.groupId._id, label: data.groupId.name }
				})
				setRequestLoader(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const handleSubmit = (values) => {
		setSaveLoading(true)

		const payload = {
			name: values.name,
			groupId: values.groupId.value,
			translations: {
				en: {
					name: values.nameEn
				}
			}
		}

		http
			.patch(`/filters/items/${id}`, payload)
			.then(() => {
				toast.success('Filtre düzenlendi!')
				navigate('/filters')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Filtreyi Düzenle">
			{requestLoader && <CardLoader />}
			{!requestLoader && <FilterItemForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />}
		</PageLayout>
	)
}
