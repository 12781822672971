import Yup from './config'

export const UpdateCustomerSchema = Yup.object().shape({
	email: Yup.string().email().required(),
	password: Yup.string().min(8).max(16),
	name: Yup.string().required(),
	surname: Yup.string().required(),
	isActive: Yup.boolean(),
	isApproved: Yup.boolean(),
	contactPermission: Yup.boolean(),
	phone: Yup.number(),
	identityNumber: Yup.string().matches(/^[1-9]{1}[0-9]{9}[02468]{1}$/)
})
