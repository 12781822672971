function Loader() {
	return (
		<div
			className="position-fixed vw-100 vh-100 bg-primary d-flex justify-content-center align-items-center"
			style={{ zIndex: 9999 }}>
			<span className="spinner-border text-light" style={{ width: '4em', height: '4em' }} />
		</div>
	)
}

export default Loader
