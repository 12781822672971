import { DndContext, closestCenter } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

function TableRow({ work }) {
	const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({ id: work.id })

	const style = {
		transform: CSS.Transform.toString(transform),
		transition
	}

	const buttonStyle = {
		background: 'none',
		border: 'none'
	}

	return (
		<tr ref={setNodeRef} style={style} {...attributes} className="sortable-row">
			<td>
				<button ref={setActivatorNodeRef} {...listeners} style={buttonStyle}>
					<i className="bi bi-arrows-expand"></i>
				</button>
			</td>
			<td>{work.name}</td>
			<td>{work.stockCode}</td>
			<td>{work.artist}</td>
		</tr>
	)
}

export default function SortableTable({ works, onChangeSort }) {
	const handleDragEnd = ({ active, over }) => {
		if (!over) return

		if (active.id === over.id) return

		const activeIndex = works.findIndex((work) => work.id === active.id)
		const overIndex = works.findIndex((work) => work.id === over.id)
		onChangeSort(arrayMove(works, activeIndex, overIndex))
	}

	return (
		<table className="table">
			<thead>
				<tr>
					<th></th>
					<th>Eser</th>
					<th>Stok Kodu</th>
					<th>Sanatçı</th>
				</tr>
			</thead>
			<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
				<tbody>
					<SortableContext items={works} strategy={verticalListSortingStrategy}>
						{works.map((work) => (
							<TableRow key={work.id} work={work} />
						))}
					</SortableContext>
				</tbody>
			</DndContext>
		</table>
	)
}
