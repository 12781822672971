import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import FilterItemForm from '../../components/forms/FilterItemForm'

export function FilterItemCreate() {
	const navigate = useNavigate()

	const initialValues = {
		name: '',
		nameEn: '',
		groupId: ''
	}

	const [saveLoading, setSaveLoading] = useState(false)

	const handleSubmit = (values) => {
		setSaveLoading(true)

		const payload = {
			name: values.name,
			groupId: values.groupId.value,
			translations: {
				en: {
					name: values.nameEn
				}
			}
		}

		http
			.post('/filters/items', payload)
			.then(() => {
				toast.success('Filtre eklendi!')
				navigate('/filters')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Yeni Filtre">
			<FilterItemForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />
		</PageLayout>
	)
}
