import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import WorkForm from '../../components/forms/WorkForm'

export function WorkEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [requestLoader, setRequestLoader] = useState(true)
	const [initialValues, setInitialValues] = useState({
		artistId: '',
		stockCode: '',
		stock: '',
		price: '',
		shippingPrice: '',
		discountedPrice: '',
		hasVat: '',
		vatRate: '',
		name: '',
		description: '',
		gallery: [{ image: '' }],
		filters: [],
		nameEn: '',
		descriptionEn: ''
	})
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/works/${id}`)
			.then(({ data }) => {
				setInitialValues({
					artistId: { value: data.artistId._id, label: data.artistId.name },
					stockCode: data.stockCode,
					stock: data.stock,
					price: data.price,
					discountedPrice: data.discountPrice,
					shippingPrice: data.shippingPrice,
					hasVat: data.hasVat,
					vatRate: data.vatRate,
					name: data.name,
					description: data.description,
					gallery: data.gallery.map((item) => ({ image: item })),
					filters: data.filters.map((filter) => ({ value: filter._id, label: filter.name })),
					status: data.status,
					nameEn: data.translations.en.name,
					descriptionEn: data.translations.en.description
				})
				setRequestLoader(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const handleSubmit = (values) => {
		setSaveLoading(true)

		const payload = {
			...values,
			artistId: values.artistId.value,
			discountedPrice: values.discountedPrice || 0,
			filters: values.filters.map((filter) => filter.value),
			gallery: values.gallery.map((item) => item.image),
			vatRate: values.vatRate || 0,
			translations: {
				en: {
					name: values.nameEn,
					description: values.descriptionEn
				}
			}
		}
		delete payload.nameEn
		delete payload.descriptionEn

		http
			.patch(`/works/${id}`, payload)
			.then(() => {
				toast.success('Eser düzenlendi!')
				navigate('/works')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Eseri Düzenle">
			{requestLoader && <CardLoader />}
			{!requestLoader && <WorkForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />}
		</PageLayout>
	)
}
