import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import CardLoader from '../../components/shared/CardLoader'
import PageForm from '../../components/forms/PageForm'

export default function PageEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [requestLoader, setRequestLoader] = useState(true)
	const [initialValues, setInitialValues] = useState({
		title: '',
		content: '',
		gallery: [],
		titleEn: '',
		contentEn: ''
	})
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/pages/${id}`)
			.then(({ data }) => {
				setInitialValues({
					title: data.title,
					content: data.content,
					gallery: data.gallery.map((item) => ({ image: item })),
					titleEn: data.translations.en.title,
					contentEn: data.translations.en.content
				})
				setRequestLoader(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.patch(`/pages/${id}`, payload)
			.then(() => {
				toast.success('Sayfa düzenlendi!')
				navigate('/pages')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Sayfayı Düzenle">
			{requestLoader && <CardLoader />}
			{!requestLoader && <PageForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />}
		</PageLayout>
	)
}
