import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducers from './reducers'
import initialState from './index'

function storeProvider({ children }) {
  const store = createStore(reducers, initialState)

  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

export default storeProvider
