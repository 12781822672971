import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UpdateUserSchema } from '../../utils/validations'
import { toast } from 'react-toastify'

import http from '../../utils/config/service'

import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/shared/Card'
import CardLoader from '../../components/shared/CardLoader'
import UserForm from '../../components/forms/UserForm'

export function UserEdit() {
	const { id } = useParams()
	const navigate = useNavigate()

	const [cardLoading, setCardLoading] = useState(true)
	const [initialValues, setIntialValues] = useState({
		email: '',
		password: '',
		rePassword: '',
		fullName: '',
		isActive: false
	})
	const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
		http
			.get(`/users/${id}`)
			.then(({ data }) => {
				delete data._id
				delete data.createdAt
				delete data.updatedAt
				data.password = ''
				setIntialValues({ ...initialValues, ...data })
			})
			.catch(() => navigate('/users'))
			.finally(() => setCardLoading(false))
	}, [])

	const handleSubmit = (values) => {
		setSaveLoading(true)
		delete values.rePassword

		if (!values.password) {
			delete values.password
		}

		http
			.patch(`/users/${id}`, values)
			.then(() => {
				toast.success('Yönetici düzenlendi!')
				navigate('/users')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Yöneticiyi Düzenle">
			<Card>
				{cardLoading && <CardLoader />}
				{!cardLoading && (
					<UserForm
						initialValues={initialValues}
						validationSchema={UpdateUserSchema}
						loading={saveLoading}
						onSubmit={handleSubmit}
					/>
				)}
			</Card>
		</PageLayout>
	)
}
