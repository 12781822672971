import PropTypes from 'prop-types'
import { TemplateSchema } from '../../utils/validations'

import { Formik, Form } from 'formik'

import Card from '../../components/shared/Card'
import Tab from '../../components/shared/Tab'
import FormGroup from '../../components/shared/FormGroup'
import Textbox from '../../components/shared/Textbox'
import CodeEditor from '../../components/shared/CodeEditor'
import CancelButton from '../../components/buttons/CancelButton'
import SaveButton from '../../components/buttons/SaveButton'

function TemplateForm({ initialValues, loading, onSubmit }) {
	const handleSubmit = (values) => {
		const payload = {
			...values,
			translations: {
				en: {
					value: values.valueEn
				}
			}
		}
		delete payload.valueEn

		onSubmit(payload)
	}

	return (
		<Formik enableReinitialize initialValues={initialValues} validationSchema={TemplateSchema} onSubmit={handleSubmit}>
			{({ values, handleChange, handleBlur, errors, touched }) => (
				<Form>
					<Card>
						<FormGroup label="Adı" labelFor="name">
							<Textbox
								type="text"
								name="name"
								placeholder="Şablon Adı"
								value={values.name}
								id="name"
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</FormGroup>
						<FormGroup
							label="Kodu"
							labelFor="key"
							topText="Türkçe harf içeremez ve kelimeler alt tire (_) ile ayrılmalıdır">
							<Textbox
								type="text"
								name="key"
								placeholder="Şablon Kodu"
								value={values.key}
								id="key"
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</FormGroup>
						<FormGroup label="İçerik">
							<Tab tabNavSpace="0">
								<Tab.Pane title="Türkçe">
									<CodeEditor
										name="value"
										value={values.value}
										placeholder="Lütfen HTMl kodu girin"
										onChange={handleChange}
									/>
								</Tab.Pane>
								<Tab.Pane title="İngilizce">
									<CodeEditor
										name="valueEn"
										value={values.valueEn}
										placeholder="Please enter HTML code"
										onChange={handleChange}
									/>
								</Tab.Pane>
							</Tab>
						</FormGroup>
						<hr />
						<div className="text-end">
							<CancelButton link="/templates" />
							<SaveButton loading={loading} />
						</div>
					</Card>
				</Form>
			)}
		</Formik>
	)
}

TemplateForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default TemplateForm
