import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Field } from 'formik'

import ReactSelect from 'react-select'

function Select({ name, isMulti, options, errors, touched }) {
	const theme = useSelector((state) => state.theme)

	const customSelectStyles = {
		control: (provided, state) => {
			return {
				...provided,
				boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(67 94 190 / 25%)' : 'none',
				borderColor: theme === 'light' ? '#dce7f1' : '#35354f'
			}
		},
		option: (provided, state) => {
			let backgroundColor = state.isSelected ? '#435ebe' : '#fff'
			let color = state.isSelected ? '#fff' : '#607080'
			if (theme === 'dark') {
				backgroundColor = state.isSelected ? '#28283d' : '#1b1b29'
				color = state.isSelected ? '#fff' : '#c2c2d9'
			}
			return {
				...provided,
				backgroundColor,
				color
			}
		},
		groupHeading: (provided) => ({
			...provided,
			textTransform: 'none',
			fontWeight: 600,
			fontSize: 12,
			paddingBottom: 10,
			borderBottom: '1px solid #f7f7f7',
			color: 'gray'
		}),
		multiValue: (provided) => ({
			...provided,
			borderRadius: 20,
			padding: '4px 10px',
			marginRight: 3.75,
			marginBottom: 3.75,
			backgroundColor: '#00bcd4',
			border: '1px solid #00a5bb'
		}),
		multiValueLabel: (provided) => ({
			...provided,
			fontSize: 12,
			fontWeight: 500,
			color: '#fff'
		}),
		multiValueRemove: (provided, state) => {
			return {
				...provided,
				opacity: 0.75,
				marginLeft: 8,
				padding: '4px 0 4px 8px',
				borderLeft: '1px solid #008fa1',
				color: '#fff',
				'&:hover': {
					opacity: 1,
					backgroundColor: 'transparent',
					color: '#fff'
				}
			}
		}
	}

	return (
		<>
			<Field name={name}>
				{({ field, form }) => (
					<ReactSelect
						isMulti={isMulti}
						options={options}
						noOptionsMessage={() => 'Sonuç bulunamadı!'}
						placeholder="Seçin"
						styles={customSelectStyles}
						className="custom-select"
						value={field.value}
						onChange={(val) => form.setFieldValue(field.name, val)}
					/>
				)}
			</Field>
			{errors[name] && touched[name] ? <div className="invalid-feedback d-block">{errors[name]}</div> : null}
		</>
	)
}

Select.propTypes = {
	name: PropTypes.string.isRequired,
	isMulti: PropTypes.bool,
	options: PropTypes.array.isRequired,
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired
}

Select.defaultProps = {
	isMulti: false
}

export default Select
