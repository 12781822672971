import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import TemplateForm from '../../components/forms/TemplateForm'

export default function TemplateCreate() {
	const navigate = useNavigate()

	const initialValues = {
		name: '',
		key: '',
		value: '',
		valueEn: ''
	}

	const [saveLoading, setSaveLoading] = useState(false)

	const handleSubmit = (payload) => {
		setSaveLoading(true)
		http
			.post('/definitions', payload)
			.then(() => {
				toast.success('Şablon eklendi!')
				navigate('/templates')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Yeni Şablon">
			<TemplateForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />
		</PageLayout>
	)
}
