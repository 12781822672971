import { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

function DropdownNav({ children, icon, text }) {
	const location = useLocation()
	const [active, setActive] = useState(false)
	const [open, setOpen] = useState(false)

	const toggleActive = (e) => {
		e.preventDefault()
		setOpen(!open)
	}

	useEffect(() => {
		const foundChild = children.find((child) => location.pathname.includes(child.props.link))
		if (foundChild) {
			setActive(true)
			setOpen(true)
			return
		}
		setOpen(false)
		setActive(false)
	}, [location, children])

	return (
		<li className={`sidebar-item has-sub ${active ? 'active' : ''}`}>
			<a href="#" className={`sidebar-link ${active ? 'active' : ''}`} onClick={toggleActive}>
				<i className={icon}></i>
				<span>{text}</span>
			</a>
			<ul className={`submenu ${open ? 'active' : ''}`}>
				{children.map((item, index) => (
					<li key={index} className="submenu-item">
						{item}
					</li>
				))}
			</ul>
		</li>
	)
}

DropdownNav.Item = ({ children, link }) => {
	return <NavLink to={link}>{children}</NavLink>
}

export default DropdownNav
