import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import DataTable, { createTheme } from 'react-data-table-component'

import CardLoader from './CardLoader'
import DropdownButton from '../../components/shared/DropdownButton'

createTheme('light', {
	text: {
		primary: '#607080',
		secondary: '#607080'
	},
	background: {
		default: 'transparent'
	},
	divider: {
		default: '#dedede'
	},
	highlightOnHover: {
		default: 'rgba(0,0,0,.075)',
		text: '#607080'
	}
})

createTheme('dark', {
	text: {
		primary: '#c2c2d9',
		secondary: '#c2c2d9'
	},
	background: {
		default: 'transparent'
	},
	divider: {
		default: '#dedede'
	},
	highlightOnHover: {
		default: '#2f2e3e',
		text: '#fff'
	},
	button: {
		default: '#FFFFFF',
		focus: 'rgba(255, 255, 255, .54)',
		hover: 'rgba(255, 255, 255, .12)',
		disabled: 'rgba(255, 255, 255, .18)'
	}
})

const customStyles = {
	headCells: {
		style: {
			padding: '1.3rem',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	cells: {
		style: {
			padding: '1.3rem',
			fontSize: '1rem',
			fontWeight: 400
		}
	}
}

const paginationOptions = {
	noRowsPerPage: true,
	rowsPerPageText: 'Sayfa başına gösterilecek veri',
	rangeSeparatorText: '-',
	selectAllRowsItem: false
}

function NoData() {
	return <div style={{ padding: '24px' }}>Gösterilecek kayıt bulunmuyor!</div>
}
function Table({ columns, pending, data, editable, removable, actions, firedAction }) {
	const [searchParams] = useSearchParams()
	const page = searchParams.get('page') || 1
	const theme = useSelector((s) => s.theme)
	let currentColumns = [
		{
			name: '',
			cell: (row) => {
				let currentOptions = []

				if (actions.length) {
					currentOptions = [...currentOptions, ...actions.map((action) => ({ ...action, param: row._id }))]
				}

				if (editable) {
					currentOptions.push({ param: row._id, icon: 'bi bi-pencil', text: 'Düzenle' })
				}

				if (removable) {
					currentOptions.push({ param: row._id, icon: 'bi bi-trash3', text: 'Sil' })
				}

				return (
					<DropdownButton
						variant="light"
						size="sm"
						rounded
						options={currentOptions}
						onOptionClick={(option) => firedAction(option)}>
						<i className="bi bi-three-dots-vertical"></i>
					</DropdownButton>
				)
			},
			button: true
		},
		...columns
	]

	if (!editable && !removable && !actions.length) {
		currentColumns = [...columns]
	}

	const handleChangePage = (page) => {
		window.history.pushState({}, '', '?page=' + page)
	}

	return (
		<DataTable
			customStyles={customStyles}
			columns={currentColumns}
			data={data}
			theme={theme}
			highlightOnHover
			noDataComponent={<NoData />}
			paginationDefaultPage={page}
			pagination
			paginationComponentOptions={paginationOptions}
			progressPending={pending}
			progressComponent={<CardLoader />}
			defaultSortFieldId={1}
			onChangePage={handleChangePage}
		/>
	)
}

Table.propTypes = {
	columns: PropTypes.array.isRequired,
	pending: PropTypes.bool.isRequired,
	data: PropTypes.array.isRequired,
	editable: PropTypes.bool,
	removable: PropTypes.bool,
	actions: PropTypes.array,
	firedAction: PropTypes.func
}

Table.defaultProps = {
	editable: true,
	removable: true,
	actions: []
}

export default Table
