export const status = {
	waiting: {
		className: 'secondary',
		text: 'Onay Bekliyor'
	},
	preparing: {
		className: 'info',
		text: 'Hazırlanıyor'
	},
	shipped: {
		className: 'primary',
		text: 'Kargoya Verildi'
	},
	prepared: {
		className: 'primary',
		text: 'Teslim Alınabilir'
	},
	done: {
		className: 'success',
		text: 'Tamamlandı'
	},
	canceled: {
		className: 'danger',
		text: 'İptal Edildi'
	},
	returned: {
		className: 'danger',
		text: 'İade Edildi'
	}
}

export const paymentStatus = {
	waiting: {
		className: 'warning',
		text: 'Bekliyor'
	},
	success: {
		className: 'success',
		text: 'Yapıldı'
	},
	failure: {
		className: 'danger',
		text: 'Başarısız'
	}
}

export const paymentType = {
	credit_card: 'Kredi Kartı',
	bank_transfer: 'Havale / EFT'
}

export const deliveryType = {
	gallery: 'Galeriden Teslim',
	address: 'Adrese Teslim'
}

export const paymentStatusValues = [
	{
		value: 'waiting',
		label: 'Bekliyor'
	},
	{
		value: 'success',
		label: 'Yapıldı'
	},
	{
		value: 'failure',
		label: 'Başarısız'
	}
]

export const statusValues = [
	{
		value: 'waiting',
		label: 'Onay Bekliyor'
	},
	{
		value: 'preparing',
		label: 'Hazırlanıyor'
	},
	{
		value: 'shipped',
		label: 'Kargoya Verildi'
	},
	{
		value: 'prepared',
		label: 'Teslim Alınabilir'
	},
	{
		value: 'done',
		label: 'Tamamlandı'
	},
	{
		value: 'canceled',
		label: 'İptal Edildi'
	},
	{
		value: 'returned',
		label: 'İade Edildi'
	}
]
