import classNames from 'classnames'

import { useField, ErrorMessage } from 'formik'

import Editor from '@uiw/react-textarea-code-editor'

function CodeEditor(props) {
	const [field, meta, helpers] = useField(props.name)
	const styles = classNames({
		'is-invalid': meta.error && meta.touched
	})

	return (
		<>
			<Editor
				{...field}
				{...props}
				language="html"
				padding={15}
				minHeight={200}
				className={styles}
				style={{
					fontSize: 12,
					backgroundColor: '#161b22',
					fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
				}}
			/>
			<ErrorMessage name={props.name} component="div" className="invalid-feedback" />
		</>
	)
}

export default CodeEditor
