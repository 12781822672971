import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import http from '../../utils/config/service'

import { toast } from 'react-toastify'

import PageLayout from '../../layouts/PageLayout'
import ArtistForm from '../../components/forms/ArtistForm'

export function ArtistCreate() {
	const navigate = useNavigate()

	const initialValues = {
		name: '',
		bio: '',
		bioEn: '',
		image: '',
		type: 'artist'
	}

	const [saveLoading, setSaveLoading] = useState(false)

	const handleSubmit = (values) => {
		setSaveLoading(true)

		const payload = {
			...values,
			translations: {
				en: {
					bio: values.bioEn
				}
			}
		}
		delete payload.bioEn

		http
			.post('/artists', payload)
			.then(() => {
				toast.success('Sanatçı eklendi!')
				navigate('/artists')
			})
			.catch(({ response }) => {
				if (response.data.showUser) {
					toast.error(response.data.message)
				}
			})
			.finally(() => setSaveLoading(false))
	}

	return (
		<PageLayout title="Yeni Sanatçı">
			<ArtistForm initialValues={initialValues} loading={saveLoading} onSubmit={handleSubmit} />
		</PageLayout>
	)
}
