import { useState } from 'react'
import PropTypes from 'prop-types'
import { ArtistSchema } from '../../utils/validations'

import { Formik, Form } from 'formik'

import Card from '../../components/shared/Card'
import Tab from '../../components/shared/Tab'
import FormGroup from '../../components/shared/FormGroup'
import Textbox from '../../components/shared/Textbox'
import RichText from '../../components/rich-text'
import CancelButton from '../../components/buttons/CancelButton'
import FilePicker from '../../components/file-picker'
import SaveButton from '../../components/buttons/SaveButton'

function ArtistForm({ initialValues, loading, onSubmit }) {
	const [type, setType] = useState(initialValues.type)

	const handleSubmit = (values) => {
		const payload = {
			...values,
			type
		}

		onSubmit(payload)
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={ArtistSchema}
			onSubmit={(values) => handleSubmit(values)}>
			{({ values, handleChange, handleBlur, errors, touched }) => (
				<Form>
					<div className="row">
						<div className="col-md-8">
							<Card>
								<FormGroup label="İsim" labelFor="name">
									<Textbox
										type="text"
										name="name"
										placeholder="Ad Soyad"
										value={values.name}
										id="name"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormGroup>
								<FormGroup label="Biyografi">
									<Tab tabNavSpace="0">
										<Tab.Pane title="Türkçe">
											<RichText name="bio" placeholder="Biyografi" errors={errors} touched={touched} />
										</Tab.Pane>
										<Tab.Pane title="İngilizce">
											<RichText name="bioEn" placeholder="Biography" errors={errors} touched={touched} />
										</Tab.Pane>
									</Tab>
								</FormGroup>
							</Card>
						</div>
						<div className="col-md-4">
							<Card>
								<FormGroup label="Tip" labelFor="type">
									<select
										className="form-select"
										id="type"
										value={type}
										onChange={({ target }) => setType(target.value)}>
										<option value="artist">Sanatçı</option>
										<option value="designer">Tasarımcı</option>
									</select>
								</FormGroup>
								<FormGroup label="Fotoğraf">
									<FilePicker name="image" value={values.image} />
								</FormGroup>
								<hr />
								<div className="text-end">
									<CancelButton link="/artists" />
									<SaveButton loading={loading} />
								</div>
							</Card>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

ArtistForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default ArtistForm
